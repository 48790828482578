import { useMutation, useLazyQuery, gql } from "@apollo/client";
import {

   Grid,

   MenuItem,
   Typography,
   TextField,
   Box,
   AppBar,
   Tabs,
   Tab,
   CircularProgress,
} from "@mui/material";
import { Button } from "@mui/material";
import { Dialog } from "@mui/material";
import { DialogActions } from "@mui/material";
import { DialogContent } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useApi } from "api";
import _ from "lodash";
import { useNotifications } from "notifications";
import React, { useContext, useEffect, useState } from "react";
import SpinnerButton from "SpinnerButton";
import { LastDocumentUpdateContext } from "application/App";
import { Product } from "products";
import { Formik, Form as FormikForm, Field as FormikField, FormikHelpers, FieldProps, FormikErrors, FormikProps } from "formik";
import { AutocompleteRenderInputParams, TextField as FmuiTextField } from "formik-mui";
import moment from "moment";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { User, Permissions } from "users";
import Autocomplete from "@mui/material/Autocomplete";

import { Autocomplete as FmuiAutocomplete } from "formik-mui";
import useCurrentUser from "users/useCurrentUser";
import { Doc, DocStatus, Tag, unpackDocumentTypeHierarchy, DocInput, Jurisdiction, DocTypeWithDepth, DocType } from "documents";
import {
   MetadataOptionQueryResult,
   MetadataOptionQuery,
   MetadataOptionQueryVariables,
   UserOptionsQueryResult,
   UserOptionsQueryVariables,
   UserOptionsQuery,
   DocumentTypeOptionQueryResult,
   DocumentTypeOptionQueryVariables,
   DocumentTypeOptionQuery,
   ProductOptionQuery,
   ProductOptionQueryVariables,
   ProductOptionQueryResult,
} from "./queries";
import FileDropzone from "./FileDropzone";
import { defaultOrganization, getOrganizationFilterOptions, getOrganizationOptionLabel, Organization } from "organizations";
import ClosableDialogTitle from "application/ClosableDialogTitle";
import HtmlEditor from "../announcements/HtmlEditor";
import { dialogStyle } from "application";
import OrganizationOption from "organizations/OrganizationOption";
import { pickedDateToUtcMidnight, utcToLocalDate } from "application/formats";
import DOMPurify from "dompurify";
import { useAuthentication } from "auth/AuthenticationProvider";

import { makeStyles, createStyles } from '@mui/styles';
import { createFilterOptions } from "@mui/material";
import { Plan } from "../plan";

const useStyles = makeStyles((theme) =>
   createStyles({
      ...dialogStyle(theme),
      tabs: {
         maxWidth: "100%",
      },
      loadingContent: {
         display: "flex",
         flexDirection: "column",
         alignItems: "center",
         margin: theme.spacing(4),
      },
   }),
);

interface Props {
   open: boolean;
   handleClose: (updatedDocument?: DocInput) => void;
   documentIdToEdit: number | null;
   productId?: number;
   administrationForms?: boolean;
   skipRefetchDocumentScreenQueryAfterEdit?: boolean;
}

interface FormValues {
   selectedFiles: File[];
   url: string;
   title: string;
   documentType: DocType | null;
   date: Date;
   products: Product[];
   jurisdictions: Jurisdiction[];
   organization: Organization | null;
   tags: TagOptionType[];
   usersToNotify: User[];
   documentStatus: DocStatus;
   catalogueNumber: string | undefined;
   version: string | undefined;
   comments: string;
   plans: Plan[];
}

interface TagOptionType extends Tag {
   inputValue?: string;
}
const tagFilter = createFilterOptions<TagOptionType>();

const UpsertDocumentDialog: React.FunctionComponent<Props> = (props) => {
   const classes = useStyles();
   const theme = useTheme();
   const notifications = useNotifications();
   const { postFormDataAsync } = useApi();
   const { getAccessToken } = useAuthentication();
   const { user, userHasPermission } = useCurrentUser();
   const [selectedTab, setSelectedTab] = useState(0);

   const userIsWyth = user ? user.isWyth : false;
   const userCanEditStatus = userHasPermission(Permissions.EditDocumentStatus);
   const userCanCreateTags = userHasPermission(Permissions.CreateTags);

   const creating = props.documentIdToEdit === null;

   const [getProductOptions, productOptionsQuery] = useLazyQuery<ProductOptionQueryResult, ProductOptionQueryVariables>(
      ProductOptionQuery,
      {
         variables: {
            includeAllProducts: userHasPermission(Permissions.PostEditDeleteDocumentsOutsideYourProducts),
         },
      },
   );

   const [getDocumentTypeOptions, documentTypesOptionsQuery] = useLazyQuery<
      DocumentTypeOptionQueryResult,
      DocumentTypeOptionQueryVariables
   >(DocumentTypeOptionQuery, {
      variables: {
         forUpsert: true,
         isAdministrationForm: props.administrationForms ?? false
      },
   });

   const [getUsers, usersQuery] = useLazyQuery<UserOptionsQueryResult, UserOptionsQueryVariables>(UserOptionsQuery, {
      variables: {},
   });

   const [getOptions, optionsQuery] = useLazyQuery<MetadataOptionQueryResult, MetadataOptionQueryVariables>(MetadataOptionQuery, {
      variables: {},
   });

   const documentFragment = gql`
      fragment documentForEdit on Document {
         id
         identifier
         title
         fileName
         url
         type {
            id
            name
            hasCatalogueNumber
            catalogueNumberRequired
            hasVersion
            versionRequired
            hasJurisdictions
            hasOrganization
            isRestrictedForWyth
            products {
               id
            }
         }
         date
         uploadedBy {
            id
            name
            organization {
               id
               name
            }
         }
         jurisdictions {
            id
            name
            order
         }
         products {
            id
            name
            plans{
               id
               name
            }
         }
         organization {
            id
            name
            code
            products {
               id
            }
         }
         status
         source
         tags {
            id
            value
         }
         catalogueNumber
         version
         comments
         plans {
            id
            name
         }
      }
   `;

   const [getDocument, documentQuery] = useLazyQuery<{ document: Doc }, { id: number }>(
      gql`
         query GetDocumentForEdit($id: Int!) {
            document(id: $id) {
               ...documentForEdit
            }
         }
         ${documentFragment}
      `,
      {
         fetchPolicy: "cache-and-network",
         nextFetchPolicy: "cache-first",
      },
   );
   const documentToEdit = !documentQuery.called || documentQuery.loading ? undefined : documentQuery.data?.document;

   const lastDocumentAddedContext = useContext(LastDocumentUpdateContext);

   useEffect(() => {
      if (props.open) {
         // These are lazy queries so that we don't run them until the dialogue is opened.
         getProductOptions();
         getDocumentTypeOptions();
         getUsers();
         getOptions();

         if (!creating) {
            getDocument({
               variables: {
                  id: props.documentIdToEdit!,
               },
            });
         }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [props.open, props.documentIdToEdit]);

   useEffect(() => {
      // We want to refresh the tag options when the user uploads a new document.
      if (optionsQuery.called) {
         optionsQuery.refetch();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [lastDocumentAddedContext.lastDocumentUpdate]);

   const optionsLoaded =
      !optionsQuery.loading &&
      !!optionsQuery.data &&
      !usersQuery.loading &&
      !!usersQuery.data &&
      !documentTypesOptionsQuery.loading &&
      !!documentTypesOptionsQuery.data &&
      !productOptionsQuery.loading &&
      !!productOptionsQuery.data;

   const documentTypeOptions = optionsLoaded ? unpackDocumentTypeHierarchy(documentTypesOptionsQuery.data!.documentTypes) : [];
   const productOptions = optionsLoaded ? _.orderBy(productOptionsQuery.data!.products, (p) => p.name.toLowerCase()) : [];
   const organizationOptions =
      optionsLoaded && optionsQuery.data!.organizations
         ? [defaultOrganization].concat(_.orderBy(optionsQuery.data!.organizations, (o) => o.name.toLowerCase()))
         : [];
   const userOptions = optionsLoaded
      ? _.orderBy(
         usersQuery.data!.users.filter((u) => u.id !== user?.id && !u.isAutomationUser),
         (u) => u.name.toLowerCase(),
      )
      : [];
   const tagOptions = optionsLoaded ? _.orderBy(optionsQuery.data!.tags, (t) => t.value.toLowerCase()) : [];
   const jurisdictionOptions = optionsLoaded ? _.orderBy(optionsQuery.data!.jurisdictions, (j) => j.name.toLowerCase()) : [];

   const fresh = creating || !documentToEdit;
   const initialFormValues: FormValues = {
      selectedFiles: [],
      url: fresh ? "" : documentToEdit!.url ?? "",
      title: fresh ? "" : documentToEdit!.title ?? "",
      documentType: isMyDocument(),
      date: fresh ? moment().startOf("day").toDate() : documentToEdit!.date,
      products: fresh ? productOptions.filter((p) => p.id === props.productId) : documentToEdit!.products,
      jurisdictions: fresh ? [] : documentToEdit!.jurisdictions,
      documentStatus: fresh ? DocStatus.NA : documentToEdit!.status,
      organization: fresh
         ? userIsWyth
            ? null
            : organizationOptions.find((o) => o.id === user?.organizationId) ?? null
         : documentToEdit!.organization ?? null,
      tags: fresh ? [] : documentToEdit!.tags.map((dt) => ({ id: dt.id, value: dt.value })),
      catalogueNumber: fresh ? undefined : documentToEdit!.catalogueNumber,
      version: fresh ? undefined : documentToEdit!.version,
      usersToNotify: [],
      comments: fresh ? "" : DOMPurify.sanitize(documentToEdit!.comments!),
      plans: fresh ? [] : documentToEdit!.plans
   };

   function isMyDocument() {
      if (props.administrationForms) {
         return documentTypeOptions.find(dt => dt.name == "Administration Form") ?? null
      }

      return fresh ? null : documentToEdit!.type;
   }

   function shouldShowStatus(status: DocStatus) {
      if (creating) return false;
      if (!user.isWyth || (!userCanEditStatus && status === DocStatus.NA)) return false;
      return true;
   }

   function validate(values: FormValues) {
      const errors: FormikErrors<FormValues> = {};

      if (creating && values.selectedFiles.length === 0 && values.url.length === 0) {
         errors.selectedFiles = user.isWyth ? "Either choose a file, or enter a link URL." : "Choose a file.";
      }

      if (values.url.length > 0 && !isValidHttpUrl(values.url)) {
         errors.url = "Enter a valid URL.";
      }

      if (values.url.length > 0 && values.title.length === 0) {
         errors.title = "For external links, you must enter a title.";
      }

      if (values.title.length > 200) {
         errors.title = "Title cannot be longer than 200 characters.";
      }

      if (!values.documentType) {
         errors.documentType = "Select the document type.";
      }

      if (values.products.length === 0) {
         errors.products = "Select at least one product.";
      }

      if (values.documentType?.hasOrganization && (values.organization === null || values.organization.id === 0)) {
         errors.organization = "Select an organization.";
      }

      if (
         values.documentType?.hasCatalogueNumber &&
         values.documentType?.catalogueNumberRequired &&
         (!values.catalogueNumber || values.catalogueNumber.trim() === "")
      ) {
         errors.catalogueNumber = "Enter the catalogue number.";
      }

      if (values.catalogueNumber && values.catalogueNumber.length > 50) {
         errors.catalogueNumber = "Catalogue number cannot be longer than 50 characters.";
      }

      if (values.documentType?.hasVersion && values.documentType?.versionRequired && (!values.version || values.version.trim() === "")) {
         errors.version = "Enter the version.";
      }

      if (values.version && values.version.length > 50) {
         errors.version = "Version cannot be longer than 50 characters.";
      }

      if (values.tags.some((t) => t.value.length > 100)) {
         errors.tags = "Tags cannot be longer than 100 characters.";
      }

      return errors;
   }

   function isValidHttpUrl(urlString: string) {
      let url;

      try {
         url = new URL(urlString);
      } catch (_) {
         return false;
      }

      return url.protocol === "http:" || url.protocol === "https:";
   }

   async function upload(values: FormValues, actions: FormikHelpers<FormValues>) {
      try {
         const accessToken = await getAccessToken();
         if (!!accessToken) {
            await postFormDataAsync<Doc[]>(
               "/api/documents",
               {
                  Url: values.url.length > 0 ? values.url : null,
                  DocumentTypeId: values.documentType!.id,
                  Title: values.selectedFiles.length <= 1 ? values.title : null,
                  Date: values.date.toISOString(),
                  ProductIds: values.products.map((p) => p.id),
                  JurisdictionIds: values.jurisdictions.map((j) => j.id),
                  OrganizationId: values.organization ? values.organization.id : null,
                  UserIdsToNotify: values.usersToNotify.map((u) => u.id),
                  TagIds: values.tags.filter((t) => t.id > 0).map((t) => t.id),
                  TagValues: values.tags.filter((t) => t.id === 0).map((t) => t.value),
                  CatalogueNumber: values.catalogueNumber,
                  Version: values.version,
                  Comments: values.comments,
                  PlanIds: values.plans.map(p => p.id)
               },
               accessToken,
               values.selectedFiles,
            );

            notifications.success("Document(s) posted.");

            lastDocumentAddedContext.setLastDocumentUpdate(new Date());
         }
      } catch (error: any) {
         notifications.error(error.message ?? error);
      }

      actions.setSubmitting(false);
      props.handleClose();
   }

   const [saveMutate] = useMutation<{ document: Doc }, { document: DocInput }>(
      gql`
         mutation UpdateDocument($document: DocumentInput!) {
            document {
               update(document: $document) {
                  ...documentForEdit
               }
            }
         }
         ${documentFragment}
      `,
      {
         refetchQueries: () => {
            const queriesToRefetch = ["GetMetadataOptions"];
            if (!props.skipRefetchDocumentScreenQueryAfterEdit) {
               queriesToRefetch.push("GetDocumentForDocumentScreen");
            }
            return queriesToRefetch;
         },
      },
   );

   async function saveEdits(values: FormValues, actions: FormikHelpers<FormValues>) {
      const editedDocument: DocInput = {
         id: documentToEdit!.id,
         url: values.url.length > 0 ? values.url : null,
         title: values.title.trim().length > 0 ? values.title : null,
         typeId: values.documentType!.id,
         date: values.date,
         productIds: values.products.map((p) => p.id),
         jurisdictionIds: values.jurisdictions.map((j) => j.id),
         organizationId: values.organization ? values.organization.id : null,
         status: values.documentStatus,
         tags: values.tags.map((tag) => ({ id: tag.id, value: tag.value })),
         catalogueNumber: values.catalogueNumber,
         version: values.version,
         comments: values.comments,
         planIds: values.plans.map(p=>p.id)
      };

      const updateResult = await saveMutate({ variables: { document: editedDocument } });

      if (updateResult.data?.document) {
         notifications.success("Document updated.");
      }

      actions.setSubmitting(false);
      props.handleClose(updateResult.data?.document ? editedDocument : undefined);
   }

   function renderDropzone() {
      return <FormikField component={PostFilesDropzone} name="selectedFiles" />;
   }

   function renderUrlField(formikProps: FormikProps<FormValues>) {
      return (
         <FormikField
            component={FmuiTextField}
            name="url"
            variant="outlined"
            size="small"
            label="External URL"
            fullWidth
            disabled={formikProps.values.selectedFiles.length > 0 ? true : undefined}
            helperText={creating ? "You can enter the URL of an external document rather than choosing a file." : undefined}
         />
      );
   }

   function renderFileOrLinkControls(formikProps: FormikProps<FormValues>) {
      if (creating && user.isWyth) {
         return (
            <>
               <AppBar position="static" color="default" variant="outlined">
                  <Tabs
                     indicatorColor="primary"
                     textColor="primary"
                     value={selectedTab}
                     onChange={(e, newValue) => setSelectedTab(newValue)}
                  >
                     <Tab label="File(s)" />
                     <Tab label="Link" />
                  </Tabs>
               </AppBar>
               <TabPanel value={selectedTab} index={0}>
                  {renderDropzone()}
               </TabPanel>
               <TabPanel value={selectedTab} index={1}>
                  {renderUrlField(formikProps)}
               </TabPanel>
            </>
         );
      }

      if (creating && !user.isWyth) {
         return renderDropzone();
      }

      if (documentToEdit?.fileName) {
         return (
            <>
               <Typography variant="caption">File name</Typography>
               <Typography variant="body1">{documentToEdit ? documentToEdit.fileName : ""}</Typography>
            </>
         );
      } else return renderUrlField(formikProps);
   }

   const title = (() => {
      if (creating) {
         return props.administrationForms ? "Add Administration Forms" : "Post document(s)";
      } else {
         return props.administrationForms ? "Edit Administration Forms" : "Edit document";
      }
   })();

   if (!props.open) return null;

   return (
      <Dialog
         open={props.open}
         onClose={(event, reason) => {
            if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
               props.handleClose();
            }
         }}
         maxWidth="sm"
         fullWidth={true}
         scroll="paper"
      >
         {optionsQuery.loading && (
            <>
               <ClosableDialogTitle title={title} handleClose={props.handleClose} />
               <DialogContent>
                  <div className={classes.loadingContent}>
                     <CircularProgress variant="indeterminate" color="primary" size={24} />
                     <Typography variant="body2" color="textSecondary">
                        Loading...
                     </Typography>
                  </div>
               </DialogContent>
            </>
         )}
         {!optionsQuery.loading && (
            <Formik
               initialValues={initialFormValues}
               validate={validate}
               onSubmit={creating ? upload : saveEdits}
               enableReinitialize={true}
            >
               {(formikProps) => {
                  const documentType = formikProps.values.documentType;
                  const validUsersToNotify = userOptions.filter(
                     (u) => u.isWyth || u.organizationId === formikProps.values.organization?.id,
                  );

                  const validDocTypes =
                     formikProps.values.products.length > 0
                        ? documentTypeOptions.filter(
                           (dt) =>
                              dt.products.length === 0 ||
                              formikProps.values.products.every((p) => dt.products.some((dtp) => dtp.id === p.id)),
                        )
                        : documentTypeOptions;

                  let validProducts = productOptions;
                  const filterProductsByDocType =
                     formikProps.values.documentType !== null && formikProps.values.documentType.products.length > 0;

                  if (filterProductsByDocType) {
                     validProducts = validProducts.filter((p) =>
                        p.documentTypes.some((dt) => dt.id === formikProps.values.documentType!.id),
                     );
                  }

                  const filterProductsByOrganizationProducts =
                     formikProps.values.organization !== null && formikProps.values.organization.id !== defaultOrganization.id;
                  if (filterProductsByOrganizationProducts) {
                     validProducts = validProducts.filter((p) => formikProps.values.organization!.products.some((op) => op.id === p.id));
                  }

                  let validplans = productOptions.filter(po => po.plans.length > 0).flatMap(po => po.plans).filter((v, i, a) => a.indexOf(v) === i);
                  if (formikProps.values.products.length > 0) {
                     validplans = formikProps.values.products.filter(po => po.plans.length > 0).flatMap(po => po.plans).filter((v, i, a) => a.indexOf(v) === i);
                  }

                  let validOrganizations = organizationOptions;
                  const filterOrganizationsByProduct = formikProps.values.products.length > 0;
                  if (filterOrganizationsByProduct) {
                     validOrganizations = validOrganizations.filter((o) =>
                        formikProps.values.products.some((p) => o.products.some((op) => op.id === p.id)),
                     );
                  }

                  return (
                     <>
                        <ClosableDialogTitle title={title} handleClose={props.handleClose} />
                        <DialogContent className={classes.dialogContent}>
                           <FormikForm>
                              <Grid container direction="column" spacing={2}>
                                 <Grid item className={classes.tabs}>
                                    {renderFileOrLinkControls(formikProps)}
                                 </Grid>

                                 <Grid item container spacing={1}>
                                    <Grid item xs={12}>
                                       <FormikField
                                          component={FmuiTextField}
                                          name="title"
                                          variant="outlined"
                                          size="small"
                                          label={`Title${formikProps.values.url.length === 0 ? " (optional)" : ""}`}
                                          fullWidth
                                          disabled={
                                             formikProps.isSubmitting || formikProps.values.selectedFiles.length > 1 ? true : undefined
                                          }
                                          error={formikProps.errors.title && formikProps.submitCount > 0}
                                          helperText={
                                             formikProps.values.selectedFiles.length > 1
                                                ? "Multiple files selected"
                                                : formikProps.errors.title
                                          }
                                       />
                                    </Grid>
                                 </Grid>

                                 {userIsWyth && (
                                    <Grid item container spacing={1}>
                                       <Grid item xs={12}>
                                          <FormikField
                                             name="organization"
                                             component={FmuiAutocomplete}
                                             multiple={false}
                                             options={validOrganizations}
                                             getOptionLabel={(org: Organization) => getOrganizationOptionLabel(org, user)}
                                             renderInput={(params: AutocompleteRenderInputParams) => (
                                                <TextField
                                                   {...params}
                                                   variant="outlined"
                                                   size="small"
                                                   label="Organization"
                                                   error={formikProps.touched.organization && formikProps.errors.organization !== undefined}
                                                   helperText={
                                                      formikProps.touched.organization ? formikProps.errors.organization : undefined
                                                   }
                                                />
                                             )}
                                             renderOption={(props: any, org: Organization) => <OrganizationOption props={props} organization={org} user={user} />}
                                             filterOptions={getOrganizationFilterOptions()}
                                             fullWidth
                                             disableClearable={true}
                                             disabled={!creating || formikProps.isSubmitting}
                                          />
                                       </Grid>
                                    </Grid>
                                 )}

                                 <Grid item container spacing={1}>
                                    <Grid item xs={12}>
                                       <FormikField
                                          name="products"
                                          component={FmuiAutocomplete}
                                          multiple={true}
                                          options={validProducts}
                                          handleHomeEndKeys={false}
                                          getOptionLabel={(option: Product) => option.name}
                                          renderInput={(params: AutocompleteRenderInputParams) => (
                                             <TextField
                                                {...params}
                                                variant="outlined"
                                                size="small"
                                                label="Product(s)"
                                                error={formikProps.touched.products && formikProps.errors.products !== undefined}
                                                helperText={formikProps.touched.products ? formikProps.errors.products : undefined}
                                             />
                                          )}
                                          renderOption={(props: any, product: Product) => (
                                             <div {...props} >
                                                <div>
                                                   <Typography>{product.name}</Typography>
                                                   <Typography variant="caption" color="textSecondary">
                                                      {product.program.name}
                                                   </Typography>
                                                </div>
                                             </div>
                                          )}
                                          filterSelectedOptions
                                          fullWidth
                                          disableClearable={true}
                                          disabled={formikProps.isSubmitting}
                                       />
                                    </Grid>
                                 </Grid>
                                 {userIsWyth && (
                                    <Grid item container spacing={1}>
                                       <Grid item xs={12}>
                                          <FormikField
                                             name="plans"
                                             component={FmuiAutocomplete}
                                             multiple={true}
                                             options={validplans}
                                             handleHomeEndKeys={false}
                                             getOptionLabel={(option: Plan) => option.name}
                                             renderInput={(params: AutocompleteRenderInputParams) => (
                                                <TextField
                                                   {...params}
                                                   variant="outlined"
                                                   size="small"
                                                   label="Plan type"
                                                   error={formikProps.touched.plans && formikProps.errors.plans !== undefined}
                                                   helperText={formikProps.touched.plans ? formikProps.errors.plans : undefined}
                                                />
                                             )}
                                             renderOption={(props: any, plan: Plan) => (
                                                <div {...props} >
                                                   <div>
                                                      <Typography>{plan.name}</Typography>
                                                   </div>
                                                </div>
                                             )}
                                             filterSelectedOptions
                                             fullWidth
                                             disableClearable={true}
                                             disabled={formikProps.isSubmitting}
                                          />
                                       </Grid>
                                    </Grid>
                                 )}
                                 <Grid item container spacing={1}>
                                    <Grid item xs={12}>
                                       <FormikField
                                          name="documentType"
                                          component={FmuiAutocomplete}
                                          handleHomeEndKeys={false}
                                          getOptionLabel={(option: DocTypeWithDepth) => option.name}
                                          options={validDocTypes}
                                          renderOption={(props: any, dt: DocTypeWithDepth) => (
                                             <div {...props}><div style={{ marginLeft: theme.spacing(dt.depth * 4) }}>{dt.name}</div></div>
                                          )}
                                          fullWidth
                                          disableClearable={true}
                                          disabled={formikProps.isSubmitting || props.administrationForms || formikProps.values.documentType?.name == "Administration Form"}
                                          renderInput={(params: AutocompleteRenderInputParams) => (
                                             <TextField
                                                {...params}
                                                variant="outlined"
                                                size="small"
                                                label="Document type"
                                                error={formikProps.touched.documentType && formikProps.errors.documentType !== undefined}
                                                helperText={formikProps.touched.documentType ? formikProps.errors.documentType : undefined}
                                             />
                                          )}
                                       />
                                    </Grid>
                                 </Grid>

                                 {(documentType?.hasCatalogueNumber || documentType?.hasVersion) && (
                                    <Grid item container spacing={1}>
                                       {documentType?.hasCatalogueNumber && (
                                          <Grid item xs={6}>
                                             <FormikField
                                                component={FmuiTextField}
                                                name="catalogueNumber"
                                                variant="outlined"
                                                size="small"
                                                label={`Catalogue number${!documentType.catalogueNumberRequired ? " (optional)" : ""}`}
                                                fullWidth
                                             />
                                          </Grid>
                                       )}
                                       {documentType?.hasVersion && (
                                          <Grid item xs={6}>
                                             <FormikField
                                                component={FmuiTextField}
                                                name="version"
                                                variant="outlined"
                                                size="small"
                                                label={`Version${!documentType.versionRequired ? " (optional)" : ""}`}
                                                fullWidth
                                             />
                                          </Grid>
                                       )}
                                    </Grid>
                                 )}

                                 {documentType?.hasJurisdictions && (
                                    <Grid item container spacing={1}>
                                       <Grid item xs={12}>
                                          <FormikField
                                             name="jurisdictions"
                                             component={FmuiAutocomplete}
                                             multiple={true}
                                             handleHomeEndKeys={false}
                                             options={jurisdictionOptions}
                                             getOptionLabel={(option: Jurisdiction) => option.name}
                                             filterSelectedOptions
                                             renderInput={(params: AutocompleteRenderInputParams) => (
                                                <TextField
                                                   {...params}
                                                   variant="outlined"
                                                   size="small"
                                                   label="Jurisdiction(s) (optional)"
                                                   error={
                                                      formikProps.touched.jurisdictions && formikProps.errors.jurisdictions !== undefined
                                                   }
                                                   helperText={
                                                      formikProps.touched.jurisdictions ? formikProps.errors.jurisdictions : undefined
                                                   }
                                                />
                                             )}
                                             fullWidth
                                             disabled={formikProps.isSubmitting}
                                          />
                                       </Grid>
                                    </Grid>
                                 )}

                                 <Grid item container spacing={1}>
                                    <Grid item xs={6}>
                                       <FormikField name="date">
                                          {(fieldProps: FieldProps<Date>) => (
                                             <DatePicker
                                                renderInput={(params) => <TextField size="small" {...params} />}
                                                inputFormat="yyyy-MM-dd"
                                                label="Date"
                                                disabled={fieldProps.form.isSubmitting}
                                                value={utcToLocalDate(fieldProps.field.value)}
                                                onChange={(newDate: Date | null) =>
                                                   fieldProps.form.setFieldValue("date", pickedDateToUtcMidnight(newDate))
                                                }
                                             />
                                          )}
                                       </FormikField>
                                    </Grid>

                                    {shouldShowStatus(formikProps.values.documentStatus) && (
                                       <Grid item xs={6}>
                                          <FormikField
                                             component={FmuiTextField}
                                             name="documentStatus"
                                             label="Status"
                                             select
                                             variant="outlined"
                                             size="small"
                                             fullWidth
                                             disabled={!userCanEditStatus || formikProps.isSubmitting}
                                          >
                                             {Object.values(DocStatus).map((menuStatus) => (
                                                <MenuItem key={menuStatus} value={menuStatus}>
                                                   {" "}
                                                   {menuStatus}
                                                </MenuItem>
                                             ))}
                                          </FormikField>
                                       </Grid>
                                    )}
                                 </Grid>

                                 {!props.administrationForms && (
                                    <Grid item container spacing={1}>
                                       <Grid item xs={12}>
                                          <FormikField name="tags">
                                             {(fieldProps: FieldProps<TagOptionType[]>) => (
                                                <Autocomplete
                                                   multiple
                                                   freeSolo={userCanCreateTags}
                                                   options={tagOptions as TagOptionType[]}
                                                   filterSelectedOptions
                                                   getOptionLabel={(option) => {
                                                      if (typeof option === "string") {
                                                         return option;
                                                      } else if (option.inputValue) {
                                                         return option.inputValue;
                                                      } else return option.value;
                                                   }}
                                                   value={fieldProps.field.value}
                                                   onChange={(e, newValue) => {
                                                      const newTags = newValue.reduce((tags, item) => {
                                                         if (typeof item !== "string") {
                                                            return tags.concat(item.inputValue ? { id: 0, value: item.inputValue } : item);
                                                         } else return tags;
                                                      }, [] as TagOptionType[]);

                                                      fieldProps.form.setFieldValue("tags", newTags);
                                                   }}
                                                   filterOptions={(options, params) => {
                                                      const filtered = tagFilter(options, params);

                                                      if (userCanCreateTags && params.inputValue !== "") {
                                                         filtered.push({
                                                            inputValue: params.inputValue,
                                                            id: 0,
                                                            value: `Add "${params.inputValue}"`,
                                                         });
                                                      }

                                                      return filtered;
                                                   }}
                                                   renderInput={(params) => (
                                                      <TextField {...params} variant="outlined" size="small" label="Tags (optional)" />
                                                   )}
                                                   selectOnFocus={userCanCreateTags}
                                                   clearOnBlur={userCanCreateTags}
                                                   handleHomeEndKeys={false}
                                                />
                                             )}
                                          </FormikField>
                                       </Grid>
                                    </Grid>
                                 )}
                                 <Grid item container spacing={1}>
                                    <Grid item xs={12}>
                                       {renderCommentEditor(formikProps)}
                                    </Grid>
                                 </Grid>
                                 {creating && !props.administrationForms && (
                                    <Grid item container spacing={1}>
                                       <Grid item xs={12}>
                                          <FormikField
                                             name="usersToNotify"
                                             component={FmuiAutocomplete}
                                             multiple
                                             handleHomeEndKeys={false}
                                             options={validUsersToNotify}
                                             filterSelectedOptions
                                             getOptionLabel={(u: User) => `${u.name} (${u.organization.name})`}
                                             renderInput={(params: AutocompleteRenderInputParams) => (
                                                <TextField
                                                   {...params}
                                                   variant="outlined"
                                                   size="small"
                                                   label="Notify people (optional)"
                                                   helperText="Send an email to let specific people know about the new document."
                                                />
                                             )}
                                          />
                                       </Grid>
                                    </Grid>
                                 )}
                              </Grid>
                           </FormikForm>
                        </DialogContent>
                        <DialogActions>
                           <Button onClick={() => props.handleClose()}>Cancel</Button>
                           <SpinnerButton
                              label={creating ? "Post" : "Save"}
                              color="secondary"
                              variant="contained"
                              inProgress={formikProps.isSubmitting}
                              onClick={() => formikProps.submitForm()}
                           />
                        </DialogActions>
                     </>
                  );
               }}
            </Formik>
         )}
      </Dialog>
   );
};

function renderCommentEditor(formikProps: FormikProps<FormValues>) {
   return (
      <FormikField
         component={HtmlEditor}
         content={formikProps.values.comments}
         onChangeContent={(newValue: string) => {
            if (newValue !== formikProps.values.comments) {
               // Workaround for issue described here: https://github.com/zenoamaro/react-quill/issues/609
               formikProps.setFieldValue("comments", newValue);
            }
         }}
         basicOnly={true}
         fieldLabel="Comments (optional)"
      />
   );
}

const PostFilesDropzone: React.FunctionComponent<FieldProps<File[], FormValues>> = (props) => {
   return (
      <FileDropzone
         prompt="Drag and drop files here or click to choose files"
         touched={props.form.touched.selectedFiles}
         error={props.form.errors.selectedFiles}
         setFieldValue={(files) => props.form.setFieldValue("selectedFiles", files)}
         limit={50}
      />
   );
};

interface TabPanelProps {
   children?: React.ReactNode;
   index: any;
   value: any;
}

function TabPanel(props: TabPanelProps) {
   const { children, value, index, ...other } = props;

   return (
      <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} {...other}>
         {value === index && <Box pt={2}>{children}</Box>}
      </div>
   );
}

export default UpsertDocumentDialog;
