import { useQuery, gql } from "@apollo/client";
import {   TextField, Grid, Typography, Button, Checkbox, FormControlLabel } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { getOrganizationFilterOptions, getOrganizationOptionLabel, Organization } from "./index";
import useCurrentUser from "users/useCurrentUser";
import { Skeleton } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import _ from "lodash";
import { Role, Permissions } from "users";
import { RouteNames } from "application/routes";
import { Product } from "products";
import UpsertOrganizationDialog from "./UpsertOrganizationDialog";
import ConfirmDeactivateOrganizationDialog from "./ConfirmDeactivateOrganizationDialog";
import MergeOrganizationsDialog from "./MergeOrganizationsDialog";
import AddUserDialog from "users/AddUserDialog";
import { WythOrgCode } from "./models";
import { UsersTable } from "users/UsersTable";
import OrganizationOption from "./OrganizationOption";
import { Tooltip } from "@mui/material";
import UserAccessReviewTile from "../review/UserAccessReviewTile";
import getUserNotices from "../notices/getUserNotices";
import UpdateOrganizationOfferingDialog from "./UpdateOrganizationOfferingDialog";
import { makeStyles, createStyles } from '@mui/styles';
import Autocomplete from "@mui/material/Autocomplete";


const useStyles = makeStyles((theme) =>
   createStyles({
      root: {
         padding: theme.spacing(3),
         "& tr:not([mode='delete']) .MuiTableCell-root": {
            verticalAlign: "top",
         },
      },
      organizations: {
         backgroundColor: theme.palette.grey[300], 
      },
      actions: {
         "& > button:not(:first-child)": {
            marginLeft: theme.spacing(1),
         },
      },
      editActions: {
         "& > button:not(:first-child)": {
            marginLeft: theme.spacing(1),
         },
         marginTop: theme.spacing(2)
      },
      buffer: {
         marginRight: theme.spacing(20),
      },
      productGrid: {
         columnCount: 4,
         columnGap: theme.spacing(4),
      },
   }),
);

export const OrganizationScreen: React.FunctionComponent = () => {
   const classes = useStyles();
   const navigate = useNavigate();
   const { user, userHasPermission, userLoading } = useCurrentUser();
   const { userNotices } = getUserNotices();
   const userCanManageOrganizations = userHasPermission(Permissions.ManageOrganizations);
   const userCanManageUsersInAnyOrganization = userHasPermission(Permissions.ManageUsersInAnyOrganization);
   const userCanManageAllUserAccessReviews = userHasPermission(Permissions.ManageAllUserAccessReviews);
   const userCanManageYourUserAccessReviews = userHasPermission(Permissions.ManageYourUserAccessReviews);

   const [organizationId, setOrganizationId] = useState<number | null>(null);
   const routeParams = useParams<{ id: string }>();
   useEffect(() => {
      const queryStringOrgId = routeParams.id ? parseInt(routeParams.id) : NaN;
      setOrganizationId(isNaN(queryStringOrgId) ? null : queryStringOrgId);
   }, [routeParams]);

   const [includeInactiveOrganizations, setIncludeInactiveOrganizations] = useState(false);
   const [includeInactiveUsers, setIncludeInactiveUsers] = useState(false);
   const [addingOrganization, setAddingOrganization] = useState(false);
   const [editingOrganization, setEditingOrganization] = useState(false);
   const [editingOrganizationOfferings, setEditingOrganizationOfferings] = useState(false);
   const [deactivatingOrganization, setDeactivatingOrganization] = useState(false);
   const [mergingOrganization, setMergingOrganization] = useState(false);
   const [creatingUser, setAddingUser] = useState(false);

   const optionsQuery = useQuery<{ organizations: Organization[]; products: Product[]; roles: Role[] }, { includeInactive: boolean }>(
      gql`
         query GetOrganizationScreenOptions($includeInactive: Boolean!) {
            organizations(includeWyth: true, includeInactive: $includeInactive) {
               id
               name
               code
               isInactive
               isWyth
               lastUpdated
            }
            roles {
               id
               name
               type
            }
            products(includeAllProducts: true) {
               id
               name
               program {
                  id
                  name
               }
            }
         }
      `,
      {
         variables: {
            includeInactive: includeInactiveOrganizations,
         },
         skip: userLoading,
         fetchPolicy: "network-only"
      },
   );

   const organizationOptions = _.orderBy(optionsQuery.data?.organizations, (o) => (o.isWyth ? "!" : o.name.toLowerCase())) ?? [];
   const roleOptions = optionsQuery.data?.roles ?? [];
   const productOptions = _.orderBy(optionsQuery.data?.products, (p) => p.name.toLowerCase());

   function onChangeOrganization(newSelectedOrganization: Organization | null) {
      setOrganizationId(newSelectedOrganization?.id ?? null);

      navigate(
         newSelectedOrganization
            ? RouteNames.Organization.replace(":id", newSelectedOrganization.id.toString())
            : RouteNames.Organization.replace("/:id", ""),
         { replace: true }
      );
   }

   const showUserAccessReviewNotice = userNotices.some(n => n.name == "AccessReview" && n.active);

   const organizationQuery = useQuery<{ organization: Organization }, { id: number, includeInactiveUsers: boolean }>(
      gql`
         query GetOrganizationForEdit($id: Int!, $includeInactiveUsers: Boolean) {
            organization(id: $id, includeInactiveUsers: $includeInactiveUsers) {
               id
               name
               isWyth
               code
               alternateCodes
               emailDomain
               alternateEmailDomains
               hasAzureAd
               lastUpdated
               products {
                  id
                  name
                  program {
                     id
                     name
                  }
               }
               offerings {
                  id
               }
               users {
                  id
                  organizationId
                  organization{
                     emailDomain
                     alternateEmailDomains
                  }
                  name
                  email
                  isWyth
                  isPrimary
                  isInactive
                  lastUpdated
                  lastSignin
                  products {
                     id
                     name
                  }
                  resourceProducts {
                     id
                     name
                  }
                  roles {
                     id
                     name
                  }
               }
            }
         }
      `,
      {
         skip: userLoading || organizationId === null,
         variables: {
            id: organizationId ?? 0,
            includeInactiveUsers: includeInactiveUsers,
         },
         fetchPolicy: "network-only"
      },
   );
   const selectedOrganization = organizationQuery.data?.organization ?? null;


   return (
      <div>
         {user.isWyth && (
            <div className={classes.organizations} >
            <div className={classes.root}>
            <Grid item container justifyContent="space-between">
               <Typography variant="h4">Organizations</Typography>

               <Grid item xs={12} sm={12} md={8} lg={6} xl={4}>
                  <Autocomplete
                     autoComplete
                     options={organizationOptions}
                     filterSelectedOptions
                     getOptionLabel={(org: Organization) => getOrganizationOptionLabel(org, user)}
                     value={selectedOrganization}
                     onChange={(e, newValue) => onChangeOrganization(newValue)}
                     renderInput={(params) => <TextField {...params} variant="outlined" label="Organization" />}
                     renderOption={(props: any, org) => <OrganizationOption props={props} organization={org} user={user} />}
                     filterOptions={getOrganizationFilterOptions()}
                     handleHomeEndKeys={false}
                  />



                  {userCanManageOrganizations && (
                     <Grid xs={true} item>
                        <FormControlLabel
                           control={
                              <Checkbox
                                 checked={includeInactiveOrganizations}
                                 onChange={(e, newValue) => setIncludeInactiveOrganizations(newValue)}
                                 color="primary"
                              />
                           }
                           label="Include inactive organizations"
                        />
                     </Grid>
                  )}
               </Grid>

               {userCanManageOrganizations
                  ? (
                     <Grid item className={classes.actions}>
                        <Button variant="outlined" color="primary" onClick={() => setAddingOrganization(true)}>
                           Add organization...
                        </Button>
                     </Grid>
                  )
                  : (
                           <Grid item className={classes.buffer}>{""}</Grid>
                  )
               }
               </Grid>
               </div>
               </div>
         )}


      <div className={classes.root}>
         <Helmet>
            <title>{`${selectedOrganization ? selectedOrganization.name : "Organizations"} - Concentra Partner Portal`}</title>
         </Helmet>
        
         <Grid container direction="column" spacing={3}>

            {selectedOrganization && !user.isWyth && (
               <Grid item container justifyContent="space-between">
                  <Typography variant="h4">
                     {selectedOrganization.name}
                  </Typography>

                  {userCanManageYourUserAccessReviews && (
                     <UserAccessReviewTile initiallyCollapsed={!showUserAccessReviewNotice} organizationId={selectedOrganization?.id!} />
                     )}
               </Grid>
            )}

            {user.isWyth && (
               <Grid item container justifyContent="space-between">
                  <Grid>
                     {selectedOrganization && (
                        <Typography variant="h4">
                           {selectedOrganization.name} ({selectedOrganization.code})
                        </Typography>
                     )}

                     {selectedOrganization && userCanManageOrganizations && (
                        <Grid item className={classes.editActions}>
                           <Button variant="outlined" onClick={() => setEditingOrganization(true)} disabled={!selectedOrganization}>
                              Edit organization...
                              </Button>
                              <Button variant="outlined" onClick={() => setEditingOrganizationOfferings(true)} disabled={!selectedOrganization}>
                                 Edit offerings...
                              </Button>

                           <Button
                              variant="outlined"
                              onClick={() => setDeactivatingOrganization(true)}
                              disabled={
                                 !selectedOrganization ||
                                 selectedOrganization.id === user.organizationId ||
                                 selectedOrganization.code === WythOrgCode
                              }
                           >
                              Deactivate organization...
                           </Button>
                           <Button
                              variant="outlined"
                              onClick={() => setMergingOrganization(true)}
                              disabled={!selectedOrganization || selectedOrganization.code === WythOrgCode}
                           >
                              Merge organizations...
                           </Button>
                        </Grid>
                     )}

                  </Grid>

                  {selectedOrganization && user.isWyth && userCanManageAllUserAccessReviews && (
                        <UserAccessReviewTile initiallyCollapsed={true} organizationId={selectedOrganization?.id!} />

                  )}

               </Grid>
            )}

            <Grid item container justifyContent="space-between">

               <Grid item xs={12}>
                  {organizationId && organizationQuery.loading && (
                     <Grid item>
                        <Grid container spacing={2} direction="column">
                           {[...Array(12)].map((x, index) => (
                              <Grid item key={index}>
                                 <Skeleton variant="rectangular" width="100%" height="2rem" />
                              </Grid>
                           ))}
                        </Grid>
                     </Grid>
                  )}

                  <Grid container direction="column" spacing={3}>
                     {userCanManageOrganizations && selectedOrganization && (
                        <>
                           {selectedOrganization.alternateCodes && (
                              <Grid item>
                                 <label>Alternate organization codes</label>
                                 <Typography variant="body1">{selectedOrganization.alternateCodes.split(";").join(", ")}</Typography>
                              </Grid>
                           )}

                           <Grid item>
                              <Tooltip title="Primary email domain for new users, both manually added & self-signup">
                                 <label>Email domain</label>
                              </Tooltip>
                              <Typography variant="body1">{selectedOrganization.emailDomain}</Typography>
                           </Grid>

                           {selectedOrganization.alternateEmailDomains && (
                              <Grid item>
                                 <Tooltip title="Alternate email domains, for manually added users only, no self-signup">
                                    <label>Alternate email domains</label>
                                 </Tooltip>
                                 <Typography variant="body1">{selectedOrganization.alternateEmailDomains.split(";").join(", ")}</Typography>
                              </Grid>
                           )}

                           <Grid item>
                              <label>Organization has Microsoft 365</label>
                              <Typography variant="body1">{selectedOrganization.hasAzureAd ? "Yes" : "No"}</Typography>
                           </Grid>

                           <Grid item>
                              <label>Products</label>
                              {selectedOrganization.products.length > 0 ? (
                                 <div className={classes.productGrid}>
                                    {_.orderBy(selectedOrganization.products, (p) => p.name).map((p) => (
                                       <Typography variant="body1" key={p.id}>
                                          {p.name}
                                       </Typography>
                                    ))}
                                 </div>
                              ) : (
                                 <Typography variant="body1">None</Typography>
                              )}
                           </Grid>
                        </>
                     )}


                     {selectedOrganization && (
                        <Grid item>
                           <UsersTable
                              users={selectedOrganization?.users ?? []}
                              loading={organizationQuery.loading}
                              showOrganization={false}
                              showIsPrimaryColumn={userCanManageUsersInAnyOrganization}
                              addUser={() => setAddingUser(true)}
                              getAvailableProductsForUser={(u) => selectedOrganization.products}
                              roles={roleOptions}
                              setIncludeInactiveUsers={setIncludeInactiveUsers}
                              includeInactiveUsers={includeInactiveUsers}
                           />
                        </Grid>
                     )}
                  </Grid>
               </Grid>
            </Grid>
         </Grid>

         <UpsertOrganizationDialog
            open={addingOrganization || editingOrganization}
            organizationToEdit={editingOrganization ? selectedOrganization : null}
            products={productOptions}
            existingOrganizations={organizationOptions}
            handleClose={(addedOrganizationId) => {
               setEditingOrganization(false);
               setAddingOrganization(false);

               if (addedOrganizationId) {
                  navigate(RouteNames.Organization.replace(":id", addedOrganizationId.toString()), { replace: true });
               }
            }}
            />

            <UpdateOrganizationOfferingDialog
               open={editingOrganizationOfferings}
               organizationToEdit={selectedOrganization}
               handleClose={() => setEditingOrganizationOfferings(false) }
            />

         {selectedOrganization && (
            <>
               <AddUserDialog
                  open={creatingUser}
                  handleClose={() => setAddingUser(false)}
                  organization={selectedOrganization}
                  organizations={[]}
                  roles={roleOptions}
                  includeIsPrimaryCheckbox={userCanManageUsersInAnyOrganization}
               />
               <ConfirmDeactivateOrganizationDialog
                  open={deactivatingOrganization}
                  organization={selectedOrganization}
                  handleClose={(deactivatedOrganizationId) => {
                     setDeactivatingOrganization(false);
                     if (deactivatedOrganizationId) {
                        navigate(RouteNames.Organization.replace("/:id", ""), { replace: true });
                     }
                  }}
               />
               <MergeOrganizationsDialog
                  open={mergingOrganization}
                  organization={selectedOrganization}
                  allOrganizations={organizationOptions}
                  handleClose={(targetOrganizationId) => {
                     setMergingOrganization(false);
                     if (targetOrganizationId) {
                        navigate(RouteNames.Organization.replace(":id", targetOrganizationId.toString()), { replace: true });
                     }
                  }}
               />
            </>
         )}
         </div>
      </div>
   );
};
