import React, { useEffect } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router";
import { HomeScreen } from "application/HomeScreen";
import { SearchScreen } from "search/SearchScreen";
import { DocumentScreen } from "documents/DocumentScreen";
import { ProgramScreen } from "programs/ProgramScreen";
import { ProductScreen } from "products/ProductScreen";
import { AlertList } from "alerts/AlertList";
import { OrganizationScreen } from "organizations/OrganizationScreen";
import { PartnerSpace } from "partnerspace/PartnerSpace";
import { TagsScreen } from "tags/TagsScreen";
import useCurrentUser from "users/useCurrentUser";
import { AnnouncementScreen } from "announcements/AnnouncementScreen";
import { RatesScreen } from "rates/RatesScreen";
import { Permissions } from "users";
import { UserSettingsScreen } from "users/UserSettingsScreen";
import { PageScreen } from "pages/PageScreen";
import { ListservScreen } from "../listserv/ListservScreen";
import { UsersScreen } from "../users/UsersScreen";
import { InternalRateChangeForm } from "rates/InternalRateChangeForm";
import { ExternalRateChangeForm } from "rates/ExternalRateChangeForm";
import { InterestRateChangeForm } from "rates/InterestRateChangeForm";
import ReloadScreen from "../auth/ReloadScreen";
import packageInfo  from '../../package.json';
import { useApi } from "../api";
import { useNotifications } from "../notifications";
import { UserGroupsScreen } from "../users/UserGroupsScreen";
import { UserAccessReviewScreen } from "../review/UserAccessReviewScreen";
import Layout from "./Layout";
import AuthComplete from "../auth/AuthComplete";


export enum RouteNames {
   Home = "/",
   Document = "/documents/:id?",
   Search = "/search",
   Program = "/programs/:id",
   Product = "/products/:id",
   Rates = "/rates/:rateType?/:subtype?",
   Alerts = "/alerts",
   Announcements = "/announcements",
   Organization = "/organizations/:id?",
   Users = "/users",
   Tags = "/tags",
   UserSettings = "/settings",
   AuthComplete = "/authComplete",
   Page = "/page/:name",
   Listservs = "/listservs",
   ExternalRateChange = "/capitalmarket/external",
   InternalRateChange = "/capitalmarket/internal",
   InterestRateChange = "/registeredplans",
   Reload = "/reload",
   PartnerSpace = "/PartnerSpace",
   UserGroups = "/usergroups",
   UserAccessReviews = "/useraccessreviews"
}

export const RoutesComponent: React.FunctionComponent<{}> = () => {
   const { userLoading, userHasPermission, userCanAdministerOwnOrganization, userCanAdministerAllOrganizations } = useCurrentUser();
   const { fetchObjectAsync } = useApi();
   const notifications = useNotifications();
   let location = useLocation();

   useEffect(() => {

      async function getSettings() {
         try {
            const version = packageInfo.version;
            const settingsValue = await fetchObjectAsync<{ version: string }>("meta.json");
            if (settingsValue.version !== version) {
               notifications.warning("This webpage is out of date. Please refresh your browser before continuing.");
            }
         } catch {
            console.error("Failed to get settings.");
         }
      }

      getSettings();

   }, [location]);

   return (
      <Routes>
         <Route path={RouteNames.AuthComplete} element={<AuthComplete/>} />
         <Route element={<Layout />}>
            <Route path={RouteNames.Reload} element={<ReloadScreen/>} />
            <Route path={RouteNames.Home} element={<HomeScreen/>} />
            <Route path={RouteNames.Document} element={<DocumentScreen/>} />
            <Route path={RouteNames.Program} element={<ProgramScreen/>} />
            <Route path={RouteNames.Product} element={<ProductScreen/>} />
            <Route path={RouteNames.Search} element={<SearchScreen/>} />
            <Route path={RouteNames.Rates} element={<RatesScreen/>} />
            <Route path={RouteNames.PartnerSpace} element={<PartnerSpace/>} />
            <Route path={RouteNames.Alerts} element={<AlertList/>} />
            <Route path={RouteNames.UserSettings} element={<UserSettingsScreen/>} />
            <Route path={RouteNames.Page} element={<PageScreen/>} />

            {(userLoading ||
               userHasPermission(Permissions.ChangeCapitalMarketExternalRateForAnyOrganization) ||
               userHasPermission(Permissions.ChangeCapitalMarketExternalRateForYourOrganization)) && (
                  <Route path={RouteNames.ExternalRateChange} element={<ExternalRateChangeForm/>} />
               )}

            {(userLoading ||
               userHasPermission(Permissions.ChangeCapitalMarketInternalRate)) && (
                  <Route path={RouteNames.InternalRateChange} element={<InternalRateChangeForm/>} />
               )}

            {(userLoading ||
               userHasPermission(Permissions.ChangeRegisteredPlansInterestRateForAnyOrganization) ||
               userHasPermission(Permissions.ChangeRegisteredPlansInterestRateForYourOrganization)) && (
                  <Route path={RouteNames.InterestRateChange} element={<InterestRateChangeForm/>} />
               )}

            {(userLoading ||
               userHasPermission(Permissions.ManageAnnouncements) ||
               userHasPermission(Permissions.ManageMarketingAnnouncements)) && (
                  <Route path={RouteNames.Announcements} element={<AnnouncementScreen/>} />
               )}
            {(userLoading || userHasPermission(Permissions.ManageListservs)) && <Route path={RouteNames.Listservs} element={<ListservScreen/>} />}
            {(userLoading || userCanAdministerOwnOrganization || userCanAdministerAllOrganizations) && (
               <Route path={RouteNames.Organization} element={<OrganizationScreen/>} />
            )}
            {(userLoading || userHasPermission(Permissions.ViewUsersInAnyOrganization)) && (
               <Route path={RouteNames.Users} element={<UsersScreen/>} />
            )}
            {(userLoading || userHasPermission(Permissions.ViewUsersInAnyOrganization)) && (
               <Route path={RouteNames.UserGroups} element={<UserGroupsScreen/>} />
            )}
            {(userLoading || userHasPermission(Permissions.ManageTags)) && <Route path={RouteNames.Tags} element={<TagsScreen/>} />}
            {(userLoading || userHasPermission(Permissions.ManageAllUserAccessReviews)) && (
               <Route path={RouteNames.UserAccessReviews} element={<UserAccessReviewScreen/>} />
            )}
            <Route path={"*"} element={<Navigate to={RouteNames.Home} />} />
         </Route>
      </Routes>
   );
};
