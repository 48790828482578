import { Doc } from "documents";
import { Product } from "products";
import { Alert } from "alerts";
import { Organization } from "organizations";

export interface User {
   id: number;
   name: string;
   email: string;
   isWyth: boolean;
   notifyOnCriticalStatusChanges: boolean;
   showOrganizationCodes: boolean;
   roles: Role[];
   permissions: Permissions[];
   organizationId: number;
   organization: Organization;
   products: Product[];
   resourceProducts: Product[];
   alerts: Alert[];
   favourites: Favourite[];
   userGroup: UserGroup;
   userGroupId: number;
   isPrimary: boolean;
   isAutomationUser: boolean;
   lastUpdated: null | string | Date;
   lastSignin: null | string | Date;
   isInactive: boolean;
}

export interface UserInput {
   id?: number;
   name?: string;
   email?: string;
   isPrimary: boolean;
   organizationId?: number;
   productIds: number[];
   resourceProductIds: number[];
   roleIds: number[];
   lastUpdated: null | string | Date;
   lastSignin: null | string | Date;
}

export interface UserGroup {
   id: number;
   groupName: string;
   users: User[];
}

export interface UserGroupInput {
   id?: number;
   groupName?: string;
   userIds: number[];
}

export interface Favourite {
   id: number;
   userId: number;
   user: User;
   documentId: number;
   document: Doc;
}

export enum RoleType {
   Wyth = "Concentra",
   Partner = "Partner",
}

export interface Role {
   id: number;
   name: string;
   type: RoleType;
}


export enum Permissions {
    PostEditOwnDocuments = "Post/edit own documents",
    ReplaceFileInOwnDocuments = "Replace file in own documents",
    DeleteOwnDocuments = "Delete own documents",

    EditDocumentsPostedByYourOrganization = "Edit documents posted by your organization",
    ReplaceFileInDocumentsPostedByYourOrganization = "Replace file in documents posted by your organization",
    DeleteDocumentsPostedByYourOrganization = "Delete documents posted by your organization",

    EditPartnerPostedDocuments = "Edit partner-posted documents",
    ReplaceFileInPartnerPostedDocuments = "Replace file in partner-posted documents",
    DeletePartnerPostedDocuments = "Delete partner-posted documents",
    EditDeletePartnerPostedDocumentsOutsideYourProducts = "Edit/delete partner-posted documents outside your products",

   EditWythPostedDocumentsForYourOrganization = "Edit Concentra-posted documents for your organization",
   DeleteWythPostedDocumentsForYourOrganization = "Delete Concentra-posted documents for your organization",

   EditWythPostedDocuments = "Edit Concentra-posted documents",
   ReplaceFileInWythPostedDocuments = "Replace file in Concentra-posted documents",
   DeleteWythPostedDocuments = "Delete Concentra-posted documents",

    ViewDocumentsOutsideYourProducts = "View documents outside your products",
    PostEditDeleteDocumentsOutsideYourProducts = "Post/edit/delete documents outside your products",

    EditDocumentStatus = "Edit status of documents",
    BatchEditDocumentStatus = "Batch edit status of documents",
    CreateTags = "Create tags",

    AssignProductsToUsersInYourOrganization = "Assign products to users in your organization",
   AssignProductsToWythUsers = "Assign products to Concentra users",
    AssignProductsToPartnerUsers = "Assign products to partner users",

    AssignRolesToUsersInYourOrganization = "Assign roles to users in your organization",
    AssignRolesToUsersInAnyOrganization = "Assign roles to users in any organization",

    ManageAnnouncements = "Create/edit/delete announcements",
    ManageAnnouncementsOutsideYourProducts = "Create/edit/delete announcements outside your products",
    ManageMarketingAnnouncements = "Manage marketing announcements",

    ViewUsersInAnyOrganization = "View users in any organization",
    ManageUsersInYourOrganization = "Add/edit/delete users in your organization",
    ManageUsersInAnyOrganization = "Add/edit/delete users in any organization",

   ManageRateBulletins = "Manage rate bulletins",
   ManageRateBulletinsOutsideYourProducts = "Manage rate bulletins outside your products",
   ManageProductScreens = "Manage product screens",
   ManageOrganizations = "Manage organizations",
   ManageTags = "Manage tags",
   EditHtmlPages = "Edit HTML pages",
   ManageListservs = "Manage listservs",
   UseRestrictedDocumentTypes = "Use restricted document types",

    ChangeCapitalMarketInternalRate = "Change capital market internal rate",
    ChangeCapitalMarketExternalRateForYourOrganization = "Change capital market external rate for your organization",
    ChangeCapitalMarketExternalRateForAnyOrganization = "Change capital market external rate for any organization",
    ChangeRegisteredPlansInterestRateForYourOrganization = "Change registered plans interest rate for your organization",
    ChangeRegisteredPlansInterestRateForAnyOrganization = "Change registered plans interest rate for any organization",

    AdministrateUserAccessReviews = "Administrate user access reviews",
    ManageAllUserAccessReviews = "Manage all user access reviews",
    ManageYourUserAccessReviews = "Manage your user access reviews",
    PostEditDeleteAdministrationForms = "Post/Edit/Delete administration forms"
}
