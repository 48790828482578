import { TextField, Grid, Box, Typography, Button, FormControlLabel, Checkbox } from "@mui/material";
import React, { useRef, useState } from "react";
import { Autocomplete } from "@mui/material";
import _ from "lodash";
import MaterialTable, { MTableToolbar } from "@material-table/core";
import {
   Role,
   RoleType,
   User,
   Permissions,
   UserInput,
   UpdateUserMutation,
   DeactivateUserMutation,
   userCanAssignProductsToUser,
   userCanAssignRolesToUser,
   userCanUpdateUserIdentity,
   ReactivateUserMutation
} from "users";
import { Product } from "products";
import { getOrganizationOptionLabel } from "organizations";
import useCurrentUser from "./useCurrentUser";
import { useMutation } from "@apollo/client";
import { useNotifications } from "notifications";
import { CSVLink } from "react-csv";
import { RestoreFromTrash as RestoreFromTrashIcon, GetApp as FileDownloadIcon } from "@mui/icons-material";
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
   createStyles({
      root: {
         padding: theme.spacing(3),
      },
      actions: {
         display: "flex",
         alignItems: "center",
         "& > *:not(:first-child)": {
            marginLeft: theme.spacing(1),
         },
         "& > *": {
            textDecoration: 'none'
         }
      },
      scrollList: {
         overflowY: 'scroll',
         maxHeight: '9.4em',
         //WebkitMaskImage: "linear-gradient(to bottom, black 80px, transparent 120px)"
      }
   }),
);

interface Props {
   users: User[];
   loading: boolean;
   showOrganization: boolean;
   showIsPrimaryColumn: boolean;
   getAvailableProductsForUser: (user: User) => Product[];
   roles: Role[];
   addUser: (() => void) | null;
   setIncludeInactiveUsers: ((includeInactive: boolean) => void);
   includeInactiveUsers: boolean;
}

export const UsersTable: React.FunctionComponent<Props> = (props) => {
   const { user, userHasPermission } = useCurrentUser();
   const classes = useStyles();
   const notifications = useNotifications();

   const tableRef = useRef<any>(null);

   const emailRegex = /^[A-Z0-9._%+\-']+@([A-Z0-9.-]+\.[A-Z]{2,})$/i;

   function currentUserCanAddUser() {
      return user.isWyth
         ? userHasPermission(Permissions.ManageUsersInAnyOrganization)
         : userHasPermission(Permissions.ManageUsersInYourOrganization);
   }

   function currentUserCanEditUser(userToEdit: User) {
      return (
         userCanAssignProductsToUser(user, userHasPermission, userToEdit) || userCanAssignRolesToUser(user, userHasPermission, userToEdit)
      );
   }

   function currentUserCanDeactivateUser(userToDeactivate: User) {
      return user.isWyth
         ? userHasPermission(Permissions.ManageUsersInAnyOrganization)
         : userHasPermission(Permissions.ManageUsersInYourOrganization) && user.organizationId === userToDeactivate.organizationId;
   }

   const [editUserMutate] = useMutation<{ user: { update: User } }, { user: UserInput }>(UpdateUserMutation, {
      refetchQueries: ["GetOrganizationForEdit", "GetAllUsers"],
   });

   async function editUser(updatedUser: User) {
      const userInput = {
         id: updatedUser.id,
         email: updatedUser.email.trim(),
         name: updatedUser.name.trim(),
         isPrimary: updatedUser.isPrimary,
         productIds: updatedUser.products.map((p) => p.id),
         resourceProductIds: updatedUser.resourceProducts.map((rp) => rp.id),
         roleIds: updatedUser.roles.map((r) => r.id),
         lastUpdated: updatedUser.lastUpdated,
         lastSignin: updatedUser.lastSignin
      };

      let validationError = "";

      if (userInput.roleIds.length === 0) {
         validationError = "Select at least one role.";
      }

      if (validationError) {
         notifications.error(validationError);
         throw new Error(validationError);
      }

      const updateResult = await editUserMutate({ variables: { user: userInput } });

      if (updateResult.data?.user.update) {
         notifications.success("User updated.");
      }
   }

   function validateName(editedUser: User) {
      if (tableRef.current?.state.lastEditingRow.tableData.editing == "delete") {
         return true;
      }

      return editedUser.name.trim() !== ''
   }

   function validateEmail(editedUser: User) {
      if (tableRef.current?.state.lastEditingRow.tableData.editing == "delete") {
         return true;
      }

      if (!emailRegex.test(editedUser.email)) {
         return "Enter a valid email address.";
      }

      var editedUserOrg = editedUser.organization;
      if (editedUserOrg && editedUserOrg.emailDomain?.trim()) {
         const match = emailRegex.exec(editedUser.email);
         if (match && match[1].toLowerCase() !== editedUserOrg.emailDomain.toLowerCase()) {
            const alternateEmailDomains = (editedUserOrg.alternateEmailDomains ?? "").split(";");
            if (!alternateEmailDomains.some((aed) => aed.toLowerCase() === match[1].toLowerCase())) {
               return editedUserOrg.alternateEmailDomains ?
                  "Enter an email address from one of the organization's domains." : `Enter an email address from the organization's domain (${editedUserOrg.emailDomain}).`;
            }
         }
      }
      return true;
   }

   function mapToCSV(data: User[]) {
      var results: any[] = [];

      var showOrgCodes = false;
      var showInactive = false;

      var roles = new Set();
      var products = new Set();

      data.forEach(u => {
         let entry: any;
         entry = {};
         entry.name = u.name;
         entry.email = u.email;

         if (u.lastSignin) {
            entry.lastSignin = (u.lastSignin as Date).toLocaleString();
         } else {
            entry.lastSignin = "";
         }

         if (u.organization.code) {
            showOrgCodes = true;
            entry.orgcode = '=""' + u.organization.code + '""';
            entry.organization = u.organization.name;
         }

         if (u.isInactive) {
            showInactive = true;
            entry.account = "Deactivated";
         } else {
            entry.account = "Active";
         }

         u.roles.forEach(r => {
            entry[r.name] = 'Yes';
            roles.add(r.name);
         });

         u.resourceProducts.forEach(rp => {
            entry[rp.name] = "Resources only";
            products.add(rp.name);
         });

         u.products.forEach(p => {
            entry[p.name] = "Reporting";
            products.add(p.name);
         });

         results.push(entry);
      });


      var headers = [];

      if (showOrgCodes) {
         headers.push(
            { label: 'OrgCode', key: 'orgcode' },
            { label: 'Organization', key: 'organization' }
         );
      }

      if (showInactive) {
         headers.push(
            { label: 'Account', key: 'account' },
         );
      }

      headers.push(
         { label: 'Name', key: 'name' },
         { label: 'Email', key: 'email' },
         { label: 'Last Sign-in', key: 'lastSignin' }
      );

      var rolesSorted = Array.from(roles).sort();
      var productsSorted = Array.from(products).sort();

      rolesSorted.map((r) => headers.push({ label: r as string, key: r as string }));
      productsSorted.map((p) => headers.push({ label: p as string, key: p as string }));

      return { headers: headers, data: results.sort((a, b) => a.name.localeCompare(b.name, 'en', { sensitivity: 'base' })) };

   }

   const [deactivateUserMutate] = useMutation<{ user: { deactivate: User } }, { id: number }>(DeactivateUserMutation, {
      refetchQueries: ["GetOrganizationForEdit", "GetAllUsers"],
   });

   const [reactivateUserMutate] = useMutation<{ user: { deactivate: User } }, { id: number }>(ReactivateUserMutation, {
      refetchQueries: ["GetOrganizationForEdit", "GetAllUsers"],
   });

   async function deactivateUser(userToDeactivate: User) {
      const deactivateResult = await deactivateUserMutate({ variables: { id: userToDeactivate.id } });

      if (deactivateResult.data?.user.deactivate) {
         notifications.success("User deactivated.");
      }
   }

   async function reactivateUser(userToReactivate: any) {
      const reactivateResult = await reactivateUserMutate({ variables: { id: userToReactivate.id } });

      if (!reactivateResult.data?.user.deactivate) {
         notifications.success("User reactivated.");
      }
   }

   const wythRoleOptions =
      _.orderBy(
         props.roles.filter((r) => r.type === RoleType.Wyth),
         (r) => r.name.toLowerCase(),
      ) ?? [];
   const partnerRoleOptions =
      _.orderBy(
         props.roles.filter((r) => r.type === RoleType.Partner),
         (r) => r.name.toLowerCase(),
      ) ?? [];

   return (
      <MaterialTable
         tableRef={tableRef}
         title=""
         isLoading={props.loading}
         components={{
            Toolbar: toolbarProps => (
               <Box p={2}>
                  <Grid container  justifyContent="space-between">
                     <Grid item>
                        <Typography variant="h6">Users</Typography>
                     </Grid>

                     <Grid item className={classes.actions}>
                        <MTableToolbar {...toolbarProps} />
                        {currentUserCanDeactivateUser(user) && (
                           <FormControlLabel
                              control={
                                 <Checkbox
                                    checked={props.includeInactiveUsers}
                                    onChange={(e, newValue) => props.setIncludeInactiveUsers(newValue)}
                                    color="primary"
                                 />
                              }
                              label="Include inactive users"
                           />
                        )}

                        <CSVLink {...mapToCSV(props.users)}
                           className="btn btn-primary"
                           target="_blank"
                           filename={'PortalUsersExport' + new Date().toISOString() + '.csv'}

                        >
                           <Button
                              variant="outlined"
                              startIcon={<FileDownloadIcon />}
                           >
                              Export all users
                           </Button>
                        </CSVLink>
                        {props.addUser && currentUserCanAddUser() && (
                           <Button variant="contained" color="secondary" onClick={() => props.addUser!()}>
                              Add user
                           </Button>
                        )}
                     </Grid>


                  </Grid>
               </Box>
            ),
         }}
         columns={[
            {
               title: "Name",
               field: "name",
               customSort: (a, b) => a.name.localeCompare(b.name, 'en', { sensitivity: 'base' }),
               defaultSort: "asc",
               editable: (col, u) => userCanUpdateUserIdentity(user, userHasPermission, u),
               validate: rowData => validateName(rowData)
            },
            {
               title: "Email",
               field: "email",
               customSort: (a, b) => a.email.localeCompare(b.email, 'en', { sensitivity: 'base' }),
               editable: (col, u) => userCanUpdateUserIdentity(user, userHasPermission, u),
               validate: rowData => validateEmail(rowData),
               cellStyle: { wordBreak: "break-all"}
            },
            {
               title: "Organization",
               field: "organization",
               editable: "never",
               sorting: true,
               render: (rowUser) => getOrganizationOptionLabel(rowUser.organization, user),
               customFilterAndSearch: (term, rowUser) =>
                  rowUser.organization.name.toLowerCase().includes(term.toLowerCase()) || rowUser.organization.code.includes(term),
               hidden: !props.showOrganization,
               customSort: (a, b) => a.organization.name.localeCompare(b.organization.name, 'en', { sensitivity: 'base' })
            },
            {
               title: "Products - Reporting and Resources",
               field: "products",
               filtering: true,
               customFilterAndSearch: (term, rowUser) => rowUser.products.some((p) => p.name.toLowerCase().includes(term.toLowerCase())),
               sorting: false,
               render: (rowUser) => {
                  const products = _.orderBy(rowUser.products, (p) => p.name.toLowerCase());

                  return (
                     <div className={classes.scrollList}>
                        {products.map((p) => <div key={p.id}>{p.name}</div>)}
                     </div>
                  );
               },
               editable: (col, u) => userCanAssignProductsToUser(user, userHasPermission, u),
               editComponent: (editComponentProps) => (
                  <Autocomplete
                     multiple
                     handleHomeEndKeys={false}
                     autoComplete
                     size="small"
                     options={_.orderBy(
                        _.uniqBy(
                           props.getAvailableProductsForUser(editComponentProps.rowData).concat(editComponentProps.rowData.products),
                           (p) => p.id,
                        ),
                        [(p) => (p.program) ? p.program.name : "", (p) => p.name.toLowerCase()],
                     )}
                     groupBy={(p) => p.program.name}
                     filterSelectedOptions
                     isOptionEqualToValue={(p, value) => p.id === value.id}
                     getOptionLabel={(p) => p.name}
                     value={editComponentProps.rowData.products}
                     onChange={(e, newValue) => editComponentProps.onChange(newValue)}
                     renderInput={(params) => <TextField {...params} variant="outlined" label="Reporting and Resources" />}
                  />
               ),
            },
            {
               title: "Products - Resources only",
               field: "resourceProducts",
               filtering: true,
               customFilterAndSearch: (term, rowUser) => rowUser.resourceProducts.some((p) => p.name.toLowerCase().includes(term.toLowerCase())),
               sorting: false,
               render: (rowUser) => {
                  const resourceProducts = _.orderBy(rowUser.resourceProducts, (p) => p.name.toLowerCase());

                  return (
                     <div className={classes.scrollList}>
                        {resourceProducts.map((p) => <div key={p.id}>{p.name}</div>)}
                     </div>
                  );
               },
               editable: (col, u) => userCanAssignProductsToUser(user, userHasPermission, u),
               editComponent: (editComponentProps) => (
                  <Autocomplete
                     multiple
                     handleHomeEndKeys={false}
                     autoComplete
                     size="small"
                     options={_.orderBy(
                        _.uniqBy(
                           props.getAvailableProductsForUser(editComponentProps.rowData).concat(editComponentProps.rowData.resourceProducts),
                           (p) => p.id,
                        ),
                        [(p) => (p.program) ? p.program.name : "", (p) => p.name.toLowerCase()],
                     )}
                     groupBy={(p) => p.program.name}
                     filterSelectedOptions
                     isOptionEqualToValue={(p, value) => p.id === value.id}
                     getOptionLabel={(p) => p.name}
                     value={editComponentProps.rowData.resourceProducts}
                     onChange={(e, newValue) => editComponentProps.onChange(newValue)}
                     renderInput={(params) => <TextField {...params} variant="outlined" label="Resources only" />}
                  />
               ),
            },

            {
               title: "Roles",
               field: "roles",
               filtering: true,
               customFilterAndSearch: (term, rowUser) => rowUser.roles.some((r) => r.name.toLowerCase().includes(term.toLowerCase())),
               sorting: false,
               render: (u) => (
                  <div className={classes.scrollList}>
                     {_.orderBy(u.roles, (r) => r.name.toLowerCase()).map((r) => (
                        <div key={r.id}>{r.name}</div>
                     ))}
                  </div>
               ),
               editable: (col, u) => userCanAssignRolesToUser(user, userHasPermission, u),
               editComponent: (editComponentProps) => (
                  <Autocomplete
                     multiple
                     autoComplete
                     handleHomeEndKeys={false}
                     size="small"
                     options={_.orderBy(editComponentProps.rowData.isWyth ? wythRoleOptions : partnerRoleOptions, (r) =>
                        r.name.toLowerCase(),
                     )}
                     filterSelectedOptions
                     getOptionLabel={(option) => option.name}
                     value={props.roles.filter((ro) => editComponentProps.rowData.roles.some((r) => r.id === ro.id))}
                     onChange={(e, newValue) => editComponentProps.onChange(newValue)}
                     renderInput={(params) => <TextField {...params} variant="outlined" label="Roles" />}
                  />
               ),
            },

            {
               title: "Primary",
               field: "isPrimary",
               type: "boolean",
               sorting: false,
               filtering: false,
               hidden: !props.showIsPrimaryColumn,
            },

            {
               title: "Last sign-in",
               field: "lastSignin",
               type: "datetime",
               editable: "never",
               sorting: true,
               filtering: false
            },

         ]}
         data={props.users.map((u) => ({ ...u })) ?? []}
         options={{
            filtering: true,
            search: false,
            paging: true,
            pageSize: 10,
            pageSizeOptions: [10, 50, 100],
            emptyRowsWhenPaging: false,
            paginationPosition: "both",
            grouping: false,
            toolbar: true,
            columnsButton: true
         }}
         editable={{
            isEditable: (u) => currentUserCanEditUser(u),
            onRowUpdate: editUser,
            isDeletable: (u) => (currentUserCanDeactivateUser(u) && !u.isInactive),
            onRowDelete: deactivateUser
         }}
         actions={[
            rowData => ({
               icon: () => <RestoreFromTrashIcon />,
               tooltip: 'Reactivate User',
               onClick: (event, rowData) => reactivateUser(rowData),
               hidden: !rowData.isInactive
            })
         ]}
         localization={{

            body: {
               deleteTooltip: "Deactivate user",
               editRow: {
                  deleteText: "Are you sure you want to deactivate this user?",
               },
            },
         }}
      />
   );
};
