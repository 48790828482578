import React from "react";
import { Drawer,  IconButton } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import SiteAppBar from "./SiteAppBar";
import clsx from "clsx";
import Navigation from "./Navigation";
import GlobalDocumentActions from "application/GlobalDocumentActions";
import useLocalStorage from "useLocalStorage";
import { useNotifications } from "notifications";
import { detect } from "detect-browser";
import useCurrentUser from "users/useCurrentUser";
import WaitingView from "auth/WaitingView";
import { useMsal } from "@azure/msal-react";
import { Outlet } from "react-router";
import { makeStyles } from '@mui/styles';

export const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
   root: {
      display: "flex",
   },
   content: {
      flexGrow: 1,
      transition: theme.transitions.create("margin", {
         easing: theme.transitions.easing.sharp,
         duration: theme.transitions.duration.leavingScreen,
      }),
      marginRight: -drawerWidth,
   },
   contentShift: {
      transition: theme.transitions.create("margin", {
         easing: theme.transitions.easing.easeOut,
         duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
   },
   drawer: {
      width: drawerWidth,
      flexShrink: 0,
   },
   drawerPaper: {
      width: drawerWidth,
   },
   drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-start",
   },
}));

type Props = React.PropsWithChildren<{}>;

const Layout: React.FunctionComponent<Props> = (props) => {
   const classes = useStyles();
   const notifications = useNotifications();
   const { userLoading, user } = useCurrentUser();

   const browser = detect();
   if (browser?.name === "ie") {
      notifications.warning(
         "You appear to be using Internet Explorer, which we do not support. This site may not work correctly, but it will work as intended in Chrome or Edge.",
      );
   }

   const [drawerOpen, setDrawerOpen] = useLocalStorage("navDrawerOpen", true);
   const { instance } = useMsal();
   

   return userLoading ? (
      <><WaitingView message="Fetching user info..." /></>

   ) : (user.id == 0) ? (
         <>
            <WaitingView message="No active user account found..." />
            {setTimeout(() => {instance.logoutRedirect()}, 2000)}
         </>
      ) : (
      <>
      <div className={classes.root}>
         <SiteAppBar open={drawerOpen} handleDrawerOpen={() => setDrawerOpen(true)} />

         <main
            className={clsx(classes.content, {
               [classes.contentShift]: drawerOpen,
            })}
         >
            <div className={classes.drawerHeader} />
                  <GlobalDocumentActions><Outlet /></GlobalDocumentActions>
         </main>
         <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="right"
            open={drawerOpen}
            classes={{
               paper: classes.drawerPaper,
            }}
         >
            <div className={classes.drawerHeader}>
               <IconButton onClick={() => setDrawerOpen(false)}>
                  <ChevronRight />
               </IconButton>
            </div>
            <Navigation />
         </Drawer>
      </div>
      </>
   );
};

export default Layout;
