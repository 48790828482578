import React from "react";
import { gql, useQuery } from "@apollo/client";
import { User, Permissions } from "users";
import { defaultOrganization } from "organizations";

const defaultUser: User = {
   id: 0,
   name: "",
   email: "",
   isWyth: false,
   notifyOnCriticalStatusChanges: false,
   showOrganizationCodes: false,
   roles: [],
   permissions: [],
   organizationId: 0,
   organization: defaultOrganization,
   products: [],
   resourceProducts: [],
   alerts: [],
   favourites: [],
   userGroup: null!,
   isAutomationUser: false,
   lastUpdated: null,
   lastSignin: null,
   isInactive: false,
   isPrimary: true,
   userGroupId: null!
};

const useCurrentUser = () => {
   const query = useQuery<{
      currentUser: User;
   }>(gql`
      query GetCurrentUser {
         currentUser {
            id
            name
            email
            isWyth
            notifyOnCriticalStatusChanges
            showOrganizationCodes
            isPrimary
            permissions
            products {
               id
               name
               programId
               program {
                  id
                  name
               }
            }
            resourceProducts {
               id
               name
               programId
               program {
                  id
                  name
               }
            }
            organizationId
            organization {
               id
               name
               code
               hasAzureAd
            }
            favourites {
               id
               documentId
               document {
                  id
                  identifier
                  fileName
                  catalogueNumber
                  type {
                     name
                  }
               }
            }
            userGroup {
               users {
                  id
                  name
                  organization {
                     name
                  }
               }
            }
         }
      }
   `);

   const user = query.data?.currentUser ?? defaultUser;

   const userHasPermission = React.useCallback(
      (permission: Permissions) => (query.loading || !query.data ? false : query.data.currentUser.permissions.includes(permission)),
      [query.data, query.loading],
   );

   const userCanAdministerOwnOrganization = user.isWyth
      ? userHasPermission(Permissions.AssignProductsToWythUsers)
      : userHasPermission(Permissions.AssignProductsToUsersInYourOrganization);

   const userCanAdministerAllOrganizations =
      user.isWyth &&
      (userHasPermission(Permissions.AssignProductsToPartnerUsers) ||
         userHasPermission(Permissions.AssignRolesToUsersInAnyOrganization) ||
         userHasPermission(Permissions.ManageOrganizations));

   return React.useMemo(
      () => ({
         userLoading: query.loading,
         user: user,
         userHasPermission: userHasPermission,
         userCanAdministerOwnOrganization: userCanAdministerOwnOrganization,
         userCanAdministerAllOrganizations: userCanAdministerAllOrganizations,
      }),
      [query.loading, user, userCanAdministerAllOrganizations, userCanAdministerOwnOrganization, userHasPermission],
   );
};

export default useCurrentUser;
