import { useMutation, gql } from "@apollo/client";
import {
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   
   Grid,
   
   Typography,
   TextField,
   DialogContentText,
} from "@mui/material";
import _ from "lodash";
import { useNotifications } from "notifications";
import React from "react";
import SpinnerButton from "SpinnerButton";
import { User, UserInput, Role, RoleType } from "./models";
import { Formik, Form as FormikForm, Field as FormikField, FormikHelpers, FormikErrors } from "formik";
import { TextField as FmuiTextField, CheckboxWithLabel as FmuiCheckboxWithLabel, AutocompleteRenderInputParams } from "formik-mui";

import { Product } from "products";
import { Organization, WythOrgCode, getOrganizationOptionLabel, getOrganizationFilterOptions } from "organizations";
import useCurrentUser from "./useCurrentUser";
import ClosableDialogTitle from "application/ClosableDialogTitle";

import { Autocomplete as FmuiAutocomplete } from "formik-mui";
import { dialogStyle } from "application";
import OrganizationOption from "organizations/OrganizationOption";

import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
   createStyles({
      ...dialogStyle(theme),
   }),
);

interface Props {
   open: boolean;
   handleClose: () => void;
   organization: Organization | null;
   organizations: Organization[];
   roles: Role[];
   includeIsPrimaryCheckbox: boolean;
}

interface FormValues {
   organization: Organization | null;
   name: string;
   email: string;
   isPrimary: boolean;
   products: Product[];
   resourceProducts: Product[];
   roles: Role[];
}

const AddUserDialog: React.FunctionComponent<Props> = (props) => {
   const classes = useStyles();
   const notifications = useNotifications();
   const { user } = useCurrentUser();

   const defaultRole = props.organization
      ? props.organization.code === WythOrgCode
         ? props.roles.find((r) => r.name === "Concentra user")
         : props.roles.find((r) => r.name === "Partner user")
      : null;

   const initialFormValues: FormValues = {
      organization: props.organization,
      name: "",
      email: "",
      isPrimary: true,
      products: [],
      resourceProducts: [],
      roles: defaultRole ? [defaultRole!] : [],
   };

   const emailRegex = /^[A-Z0-9._%+\-']+@([A-Z0-9.-]+\.[A-Z]{2,})$/i;

   function validate(values: FormValues) {
      const errors: FormikErrors<FormValues> = {};

      if (values.organization === null) {
         errors.organization = "Choose an organization.";
      }

      if (values.name.trim() === "") {
         errors.name = "Enter the user's name.";
      }

      if (values.email.trim() === "") {
         errors.email = "Enter the user's email address.";
      }

      if (values.email.length > 0 && !isValidEmail(values.email)) {
         errors.email = "Enter a valid email address.";
      }

      if (values.organization && values.organization.emailDomain?.trim()) {
         const match = emailRegex.exec(values.email);
         if (match && match[1].toLowerCase() !== values.organization.emailDomain.toLowerCase()) {
            const alternateEmailDomains = (values.organization.alternateEmailDomains ?? "").split(";");
            if (!alternateEmailDomains.some((aed) => aed.toLowerCase() === match[1].toLowerCase())) {
               errors.email = values.organization.alternateEmailDomains
                  ? "Enter an email address from one of the organization's domains."
                  : `Enter an email address from the organization's domain (${values.organization.emailDomain}).`;
            }
         }
      }

      if (values.roles.length === 0) {
         errors.roles = "Choose at least one role for the user.";
      }

      return errors;
   }

   function isValidEmail(email: string) {
      return emailRegex.test(email);
   }

   const [addMutate] = useMutation<{ user: { add: User } }, { user: UserInput }>(
      gql`
         mutation AddUser($user: UserInput!) {
            user {
               add(user: $user) {
                  id
                  name
                  email
                  isPrimary
                  organization {
                     id
                     name
                     code
                  }
                  products {
                     id
                     name
                  }
                  resourceProducts {
                     id
                     name
                  }
                  roles {
                     id
                     name
                  }
               }
            }
         }
      `,
      {
         refetchQueries: ["GetOrganizationForEdit", "GetAllUsers"],
      },
   );

   async function saveNewUser(values: FormValues, actions: FormikHelpers<FormValues>) {
      const newUser: UserInput = {
         name: values.name,
         email: values.email,
         isPrimary: values.isPrimary ?? true,
         organizationId: values.organization!.id,
         productIds: values.products.map((p) => p.id),
         resourceProductIds: values.resourceProducts.map((rp) => rp.id),
         roleIds: values.roles.map((r) => r.id),
         lastUpdated: null,
         lastSignin: null
      };

      const addResult = await addMutate({ variables: { user: newUser } });

      if (addResult.data?.user.add) {
         notifications.success("User added.");
      }

      actions.setSubmitting(false);
      props.handleClose();
   }

   if (!props.open) return null;

   const organizations = _.orderBy(props.organizations, (o) => (o.isWyth ? "!" : o.name.toLowerCase()));

   const wythRoles =
      _.orderBy(
         props.roles.filter((r) => r.type === RoleType.Wyth),
         (r) => r.name.toLowerCase(),
      ) ?? [];
   const partnerRoles =
      _.orderBy(
         props.roles.filter((r) => r.type === RoleType.Partner),
         (r) => r.name.toLowerCase(),
      ) ?? [];

   return (
      <Dialog open={props.open}
         onClose={(event, reason) => {
            if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
               props.handleClose();
            }
         }}
         maxWidth="sm" fullWidth={true} scroll="paper" >
         <Formik initialValues={initialFormValues} validate={validate} onSubmit={saveNewUser}>
            {(formikProps) => {
               return (
                  <>
                     <ClosableDialogTitle title="Add a user" handleClose={props.handleClose} />
                     <DialogContent className={classes.dialogContent}>
                        {user.isWyth && props.organization && (
                           <DialogContentText>{`Add a user to this organization: ${props.organization.name}`}</DialogContentText>
                        )}
                        <FormikForm>
                           <Grid container direction="column" spacing={2}>
                              {props.organization === null && (
                                 <Grid item container spacing={1}>
                                    <Grid item xs={12}>
                                       <FormikField
                                          name="organization"
                                          component={FmuiAutocomplete}
                                          handleHomeEndKeys={false}
                                          multiple={false}
                                          options={organizations}
                                          getOptionLabel={(org: Organization) => getOrganizationOptionLabel(org, user)}
                                          renderInput={(params: AutocompleteRenderInputParams) => (
                                             <TextField
                                                {...params}
                                                variant="outlined"
                                                size="small"
                                                label="Organization"
                                                error={formikProps.touched.organization && formikProps.errors.organization !== undefined}
                                                helperText={formikProps.touched.organization ? formikProps.errors.organization : undefined}
                                             />
                                          )}
                                          renderOption={(props: any, org: Organization) => <OrganizationOption props={props} organization={org} user={user} />}
                                          filterOptions={getOrganizationFilterOptions()}
                                          fullWidth
                                          disableClearable={true}
                                       />
                                    </Grid>
                                 </Grid>
                              )}

                              <Grid item container spacing={1}>
                                 <Grid item xs={12}>
                                    <FormikField
                                       component={FmuiTextField}
                                       name="name"
                                       variant="outlined"
                                       size="small"
                                       label="Name"
                                       fullWidth
                                       disabled={formikProps.isSubmitting}
                                    />
                                 </Grid>
                              </Grid>

                              <Grid item container spacing={1}>
                                 <Grid item xs={12}>
                                    <FormikField
                                       component={FmuiTextField}
                                       name="email"
                                       variant="outlined"
                                       size="small"
                                       label="Email"
                                       fullWidth
                                       disabled={formikProps.isSubmitting}
                                    />
                                 </Grid>
                              </Grid>

                              <Grid item container spacing={1}>
                                 <Grid item xs={12}>
                                    <FormikField
                                       name="products"
                                       component={FmuiAutocomplete}
                                       handleHomeEndKeys={false}
                                       multiple={true}
                                       options={
                                          formikProps.values.organization
                                             ? _.orderBy(formikProps.values.organization.products, (p) => p.name.toLowerCase())
                                             : []
                                       }
                                       getOptionLabel={(option: Product) => option.name}
                                       renderInput={(params: AutocompleteRenderInputParams) => (
                                          <TextField
                                             {...params}
                                             variant="outlined"
                                             size="small"
                                             label="Products - Reporting and resources"
                                             error={formikProps.touched.products && formikProps.errors.products !== undefined}
                                             helperText={formikProps.touched.products ? formikProps.errors.products : undefined}
                                          />
                                       )}
                                       renderOption={(props: any, product: Product) => (
                                          <div {...props}>
                                          <div>
                                             <Typography>{product.name}</Typography>
                                             <Typography variant="caption" color="textSecondary">
                                                {product.program.name}
                                             </Typography>
                                             </div>
                                          </div>
                                       )}
                                       fullWidth
                                       disableClearable={true}
                                       disabled={!formikProps.values.organization || formikProps.isSubmitting}
                                    />
                                 </Grid>
                              </Grid>

                              <Grid item container spacing={1}>
                                 <Grid item xs={12}>
                                    <FormikField
                                       name="resourceProducts"
                                       component={FmuiAutocomplete}
                                       handleHomeEndKeys={false}
                                       multiple={true}
                                       options={
                                          formikProps.values.organization
                                             ? _.orderBy(formikProps.values.organization.products, (p) => p.name.toLowerCase())
                                             : []
                                       }
                                       getOptionLabel={(option: Product) => option.name}
                                       renderInput={(params: AutocompleteRenderInputParams) => (
                                          <TextField
                                             {...params}
                                             variant="outlined"
                                             size="small"
                                             label="Products - Resources only"
                                             error={formikProps.touched.resourceProducts && formikProps.errors.resourceProducts !== undefined}
                                             helperText={formikProps.touched.resourceProducts ? formikProps.errors.resourceProducts : undefined}
                                          />
                                       )}
                                       renderOption={(props: any, resourceProduct: Product) => (
                                          <div {...props} >
                                          <div>
                                             <Typography>{resourceProduct.name}</Typography>
                                             <Typography variant="caption" color="textSecondary">
                                                {resourceProduct.program.name}
                                             </Typography>
                                             </div>
                                          </div>
                                       )}
                                       fullWidth
                                       disableClearable={true}
                                       disabled={!formikProps.values.organization || formikProps.isSubmitting}
                                    />
                                 </Grid>
                              </Grid>

                              <Grid item container spacing={1}>
                                 <Grid item xs={12}>
                                    <FormikField
                                       name="roles"
                                       component={FmuiAutocomplete}
                                       handleHomeEndKeys={false}
                                       multiple={true}
                                       options={
                                          formikProps.values.organization
                                             ? formikProps.values.organization.isWyth
                                                ? wythRoles
                                                : partnerRoles
                                             : []
                                       }
                                       getOptionLabel={(option: Role) => option.name}
                                       renderInput={(params: AutocompleteRenderInputParams) => (
                                          <TextField
                                             {...params}
                                             variant="outlined"
                                             size="small"
                                             label="Role(s)"
                                             error={formikProps.touched.roles && formikProps.errors.roles !== undefined}
                                             helperText={formikProps.touched.roles ? formikProps.errors.roles : undefined}
                                          />
                                       )}
                                       fullWidth
                                       disableClearable={true}
                                       disabled={formikProps.values.organization === null || formikProps.isSubmitting}
                                    />
                                 </Grid>
                              </Grid>

                              {props.includeIsPrimaryCheckbox && (
                                 <Grid item container spacing={1}>
                                    <Grid item xs={12}>
                                       <FormikField
                                          component={FmuiCheckboxWithLabel}
                                          type="checkbox"
                                          name="isPrimary"
                                          Label={{ label: "Primary account" }}
                                       />
                                    </Grid>
                                 </Grid>
                              )}
                           </Grid>
                        </FormikForm>
                     </DialogContent>
                     <DialogActions>
                        <Button onClick={props.handleClose}>Cancel</Button>
                        <SpinnerButton
                           label="Add"
                           color="secondary"
                           variant="contained"
                           inProgress={formikProps.isSubmitting}
                           onClick={() => {
                              formikProps.submitForm();
                           }}
                        />
                     </DialogActions>
                  </>
               );
            }}
         </Formik>
      </Dialog>
   );
};

export default AddUserDialog;
