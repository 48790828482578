import {   Typography } from "@mui/material";
import React from "react";
import clsx from "clsx";
import { announcementStyles } from "./styles";
import ReactQuill, { Quill } from "react-quill";
import ImageCompress from "quill-image-compress";
import "react-quill/dist/quill.snow.css";
import { makeStyles, createStyles } from '@mui/styles';

Quill.register("modules/imageCompress", ImageCompress);

const useStyles = makeStyles((theme) =>
   createStyles({
      ...announcementStyles(theme),
      quillLabel: {
         position: "absolute",
         top: "-9px",
         left: "10px",
         padding: "0 5px",
         background: "white",
         color: "rgba(0, 0, 0, 0.54)",
         zIndex: 100,
      },
      quill: {
         position: "relative",
         zIndex: 10,
         "& .ql-toolbar": {
            borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
            boxSizing: "border-box",
         },
         "& .ql-container": {
            ...announcementStyles(theme).htmlContent,
            borderRadius: `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,
            fontFamily: theme.typography.fontFamily,
            boxSizing: "border-box",
         },
         "& .ql-editor": {
            maxHeight: "50vh",

            "&::before": {
               fontStyle: "normal",
            },
         },

         "& .ql-tooltip": {
            left: "50% !important",
            transform: "translate(-50%, -50%) !important",
         },
         "&:hover .ql-toolbar": {
            borderTop: "1px black solid",
            borderLeft: "1px black solid",
            borderRight: "1px black solid",
         },
         "&:hover .ql-container": {
            borderBottom: "1px black solid",
            borderLeft: "1px black solid",
            borderRight: "1px black solid",
         },
      },
      quillError: {
         "& .ql-toolbar, & .ql-container": {
            borderColor: theme.palette.error.main,
         },
      },
      quillParent: {
         position: "relative",
         padding: "1px 0",
         "&:focus-within .ql-toolbar": {
            borderColor: theme.palette.primary.main,
            borderStyle: "solid",
            borderWidth: "2px 2px 1px 2px",
            margin: "-1px -1px 0 -1px ",
         },
         "&:focus-within .ql-container": {
            borderColor: theme.palette.primary.main,
            borderStyle: "solid",
            borderWidth: "1px 2px 2px 2px",
            margin: "0 -1px -1px -1px",
         },
         "&:focus-within .quillLabel": {
            color: `${theme.palette.primary.main}`,
         },
      },
   }),
);

interface Props {
   fieldLabel?: string;
   content: string;
   onChangeContent: (newValue: string) => void;
   isInErrorState?: boolean;
   imageOnly?: boolean;
   basicOnly?: boolean;
}

const HtmlEditor: React.FunctionComponent<Props> = (props) => {
   const classes = useStyles();

   return (
      <div className={classes.quillParent}>
         <Typography variant="caption" className={clsx(classes.quillLabel, "quillLabel")}>
            {props.fieldLabel}
         </Typography>
         <ReactQuill
            theme="snow"
            className={clsx(classes.quill, classes.htmlContent, {
               [classes.quillError]: props.isInErrorState,
            })}
            modules={{
               clipboard: {
                  matchVisual: false,
               },
               toolbar:
                  props.imageOnly === true
                     ? [["image"]]
                     : props.basicOnly === true
                     ? [
                          ["bold", "italic", "underline"],
                          [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
                          ["link"],
                          ["clean"],
                       ]
                     : /* default */ [
                          [{ header: [1, 2, false] }],
                          ["bold", "italic", "underline"],
                          [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
                          ["link", "image"],
                          ["clean"],
                       ],
               imageCompress: {
                  quality: 0.9,
                  maxWidth: 480,
                  imageType: "image/jpeg",
               },
            }}
            formats={
               props.imageOnly === true
                  ? ["image"]
                  : props.basicOnly === true
                  ? ["bold", "italic", "underline", "list", "bullet", "indent", "link"]
                  : /* default */ ["header", "bold", "italic", "underline", "list", "bullet", "indent", "link", "image"]
            }
            value={props.content}
            onChange={props.onChangeContent}
         ></ReactQuill>
      </div>
   );
};

export default HtmlEditor;
