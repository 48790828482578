import React, { useState } from "react";
import {   List, ListItem, ListItemIcon, ListItemText, Collapse } from "@mui/material";
import {
   StarBorder,
   Search as SearchIcon,
   Home as HomeIcon,
   ExpandMore as ExpandMoreIcon,
   ExpandLess as ExpandLessIcon,
   Notifications as NotificationsIcon,
   ViewColumn as ViewColumnIcon,
   AccountCircle as AccountCircleIcon,
   ExitToApp as ExitToAppIcon,
   Business as BusinessIcon,
   Announcement as AnnouncementIcon,
   TrendingUp as TrendingUpIcon,
   Settings as SettingsIcon,
   SettingsApplications as SettingsApplicationsIcon,
   LocalOffer as LocalOfferIcon,
   Help as HelpIcon,
   Phone as PhoneIcon,
   ViewHeadline as ViewHeadlineIcon,
   CloudUpload as CloudUploadIcon,
   AllInbox as AllInboxIcon,
   People as PeopleIcon,
   SupervisorAccount as SupervisorAccountIcon,
   SupervisedUserCircle as SupervisedUserCircleIcon,
   Grade as GradeIcon,
   Warning as WarningIcon,
   Description as Description
} from "@mui/icons-material";
import { useNavigate, useParams } from "react-router";
import { RouteNames } from "./routes";
import useCurrentUser from "../users/useCurrentUser";
import useCurrentPrimaryUser from "../users/useCurrentPrimaryUser";
import { getIconForDocument, getDocumentUrl } from "../documents";
import useLocalStorage from "useLocalStorage";
import { Permissions } from "users";
import _ from "lodash";
import { useAuthentication } from "auth/AuthenticationProvider";
import UpsertDocumentDialog from "../documents/UpsertDocumentDialog";
import ConfirmSwitchUserDialog from "../users/ConfirmSwitchUserDialog";
import getUserNotices from "../notices/getUserNotices";
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
   createStyles({
      submenuParent: {
         paddingLeft: theme.spacing(2.5),
         borderTop: "1px solid rgba(0, 0, 0, 0.1);",
      },
      parentText: {
         textTransform: "uppercase",
         fontSize: "small",
      },
      navigationList: {
         "& .MuiListItem-button.MuiListItem-root": {

            '&:hover': {
               backgroundColor: '#ffcb31',
            }
         }
      },
      submenuItem: {
         paddingLeft: 32,
      },
      favouriteText: {
         fontSize: "small",
      },
      favouriteIcon: {
         minWidth: "2em",
         alignSelf: "flex-start",
         position: "relative",
         top: "0.4em",
      },
      signOutItem: {
         borderBottom: "1px solid rgba(0, 0, 0, 0.1);",
      },
      warning: {

      }
   }),
);

const Navigation: React.FunctionComponent = () => {
   const [postDocumentDialogOpen, setPostDocumentDialogOpen] = useState(false);
   const productRouteMatch = useParams<{ id: string }>();

   const classes = useStyles();
   const { resetPassword, logOut } = useAuthentication();

   const navigate = useNavigate();
   const { user, userHasPermission, userCanAdministerOwnOrganization, userCanAdministerAllOrganizations } = useCurrentUser();
   const { currentPrimaryUser } = useCurrentPrimaryUser();
   const { userNotices } = getUserNotices();

   const userHasReportingProducts = user.products.length > 0;

   const userCanManageAnnouncements = userHasPermission(Permissions.ManageAnnouncements) || userHasPermission(Permissions.ManageMarketingAnnouncements);
   const userCanManageListservs = userHasPermission(Permissions.ManageListservs);
   const userCanManageTags = userHasPermission(Permissions.ManageTags);
   const userCanManageExternalRates = userHasPermission(Permissions.ChangeCapitalMarketExternalRateForAnyOrganization) || userHasPermission(Permissions.ChangeCapitalMarketExternalRateForYourOrganization);
   const userCanManageInternalRates = userHasPermission(Permissions.ChangeCapitalMarketInternalRate);
   const userCanManageInterestRates = userHasPermission(Permissions.ChangeRegisteredPlansInterestRateForAnyOrganization) || userHasPermission(Permissions.ChangeRegisteredPlansInterestRateForYourOrganization);

   const userCanAccessAdminScreens =
      userCanAdministerAllOrganizations ||
      userCanAdministerOwnOrganization ||
      userCanManageAnnouncements ||
      userCanManageTags;

   const userCanAccessManageRatesScreens =
      userCanManageExternalRates ||
      userCanManageInternalRates ||
      userCanManageInterestRates;

   const [productsOpen, setProductsOpen] = useLocalStorage("navProductsOpen", true);
   const [favouritesOpen, setFavouritesOpen] = useLocalStorage("navFavouritesOpen", true);
   const [profileOpen, setProfileOpen] = useLocalStorage("navProfileOpen", false);
   const [accountsOpen, setAccountsOpen] = useLocalStorage("navAccountsOpen", false);
   const [legalOpen, setLegalOpen] = useLocalStorage("navLegalOpen", false);
   const [adminOpen, setAdminOpen] = useLocalStorage("navAdminOpen", false);
   const [manageRatesOpen, setManageRatesOpen] = useLocalStorage("navManageRatesOpen", false);

   const [confirmingSwitchingUser, setConfirmingSwitchingUser] = useState(false);

   const [userIdTarget, setUserIdTarget] = useState(0);
   const [userTitleTarget, setUserTitleTarget] = useState("");

   const showUserAccessReviewNotice = userNotices.some(n => n.name == "AccessReview" && n.active);

   return (
      <>
         <List className={classes.navigationList}>
            <ListItem button onClick={() => navigate(RouteNames.Home)}>
               <ListItemIcon>
                  <HomeIcon />
               </ListItemIcon>
               <ListItemText primary="Home" />
            </ListItem>

            {!currentPrimaryUser.organization.hasAzureAd && (
               <>
                  <ListItem button onClick={() => setProfileOpen(!profileOpen)}>
                     <ListItemIcon>
                        <AccountCircleIcon />
                     </ListItemIcon>
                     <ListItemText primary="My profile" />
                     {profileOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListItem>
                  <Collapse in={profileOpen} timeout="auto" unmountOnExit>
                     <List component="div" dense disablePadding>
                        <ListItem button
                           className={classes.submenuItem}
                           onClick={() => {
                              if (resetPassword) {
                                 resetPassword();
                              }
                           }}
                        >
                           <ListItemText primary="Change password" />
                        </ListItem>
                     </List>
                  </Collapse>
               </>
            )}

            {user.userGroup && (
               <>
                  <ListItem button onClick={() => setAccountsOpen(!accountsOpen)}>
                     <ListItemIcon>
                        <SupervisedUserCircleIcon />
                     </ListItemIcon>
                     <ListItemText primary="Switch account" />
                     {accountsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListItem>
                  <Collapse in={accountsOpen} timeout="auto" unmountOnExit>
                     <List component="div" dense disablePadding>
                        {user.userGroup &&
                           _.orderBy(user.userGroup.users, [(u) => u.organization.name.toLowerCase()]).map((u) => (
                              <ListItem
                                 button
                                 key={u.id}
                                 className={classes.submenuItem}
                                 onClick={() => {
                                    setUserIdTarget(u.id);
                                    setUserTitleTarget(`${u.name} (${u.organization.name})`);
                                    setConfirmingSwitchingUser(true);
                                 }}
                              >
                                 <ListItemText primary={`${u.name} (${u.organization.name})`} />
                              </ListItem>
                           ))}
                     </List>
                  </Collapse>
               </>
            )}

            <ListItem button className={classes.signOutItem} onClick={() => logOut!()}>
               <ListItemIcon>
                  <ExitToAppIcon />
               </ListItemIcon>
               <ListItemText primary="Sign out" />
            </ListItem>

            <ListItem button onClick={() => navigate(RouteNames.Search)}>
               <ListItemIcon>
                  <SearchIcon />
               </ListItemIcon>
               <ListItemText primary="Search" />
            </ListItem>

            {userHasReportingProducts && userHasPermission(Permissions.PostEditOwnDocuments) && (
               <ListItem button onClick={() => setPostDocumentDialogOpen(true)}>
                  <ListItemIcon>
                     <CloudUploadIcon />
                  </ListItemIcon>
                  <ListItemText primary="Post a document" />
               </ListItem>
            )}

            <ListItem button onClick={() => setProductsOpen(!productsOpen)}>
               <ListItemIcon>
                  <ViewColumnIcon />
               </ListItemIcon>
               <ListItemText primary="My products" />
               {productsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>
            <Collapse in={productsOpen} timeout="auto" unmountOnExit>
               <List component="div" dense disablePadding>
                  {user &&
                     _.orderBy(_.uniqBy(_.union(user.products, user.resourceProducts), 'id'), [(p) => p.program.name.toLowerCase(), (p) => p.name.toLowerCase()]).map(
                        (product, i, products) => [
                           i === 0 || products[i - 1].program.name !== product.program.name ? (
                              <ListItem
                                 button
                                 key={`pd${product.program.id}`}
                                 onClick={() => navigate(RouteNames.Program.replace(":id", product.program.id.toString()))}
                                 className={classes.submenuParent}
                              >
                                 <ListItemText primary={product.program.name} classes={{ primary: classes.parentText }} />
                              </ListItem>
                           ) : null,

                           <ListItem
                              button
                              key={`pd${product.id}`}
                              className={classes.submenuItem}
                              onClick={() => navigate(RouteNames.Product.replace(":id", product.id.toString()))}
                           >
                              <ListItemText primary={product.name} classes={{ primary: classes.favouriteText }} />
                           </ListItem>,
                        ],
                     )}
               </List>
            </Collapse>
            <ListItem button onClick={() => setFavouritesOpen(!favouritesOpen)}>
               <ListItemIcon>
                  <StarBorder />
               </ListItemIcon>
               <ListItemText primary="My favourites" />
               {favouritesOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>
            <Collapse in={favouritesOpen} timeout="auto" unmountOnExit>
               <List component="div" dense disablePadding>
                  {user &&
                     user.favourites.map((f) => (
                        <ListItem
                           button
                           key={f.id}
                           className={classes.submenuItem}
                           onClick={() => navigate(getDocumentUrl(f.document))}
                        >
                           <ListItemIcon className={classes.favouriteIcon}>{getIconForDocument(f.document, "1rem")}</ListItemIcon>
                           <ListItemText primary={f.document.identifier} classes={{ primary: classes.favouriteText }} />
                        </ListItem>
                     ))}
               </List>
            </Collapse>

            <ListItem button onClick={() => navigate(RouteNames.Alerts)}>
               <ListItemIcon>
                  <NotificationsIcon />
               </ListItemIcon>
               <ListItemText primary="My alerts" />
            </ListItem>
            {!user.isWyth && (
               <ListItem button onClick={() => navigate(RouteNames.PartnerSpace)}>
                  <ListItemIcon>
                     <Description />
                  </ListItemIcon>
                  <ListItemText primary="Administration forms" />
               </ListItem>
            )
            }
            <ListItem button onClick={() => navigate(RouteNames.UserSettings)}>
               <ListItemIcon>
                  <SettingsIcon />
               </ListItemIcon>
               <ListItemText primary="My settings" />
            </ListItem>

            <ListItem button onClick={() => navigate(RouteNames.Rates.replace("/:rateType?/:subtype", ""))}>
               <ListItemIcon>
                  <TrendingUpIcon />
               </ListItemIcon>
               <ListItemText primary="Concentra's rates" />
            </ListItem>

            <ListItem button onClick={() => navigate("/page/Help")}>
               <ListItemIcon>
                  <HelpIcon />
               </ListItemIcon>
               <ListItemText primary="Help" />
            </ListItem>

            <>
               <ListItem button onClick={() => setLegalOpen(!legalOpen)}>
                  <ListItemIcon>
                     <ViewHeadlineIcon />
                  </ListItemIcon>
                  <ListItemText primary="Legal & privacy" />
                  {legalOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
               </ListItem>
               <Collapse in={legalOpen} timeout="auto" unmountOnExit>
                  <List component="div" dense disablePadding>

                     <ListItem
                        button
                        className={classes.submenuItem}
                        onClick={() => window.open("https://www.concentra.ca/accessibility", "_blank")}
                     >
                        <ListItemText primary="Accessibility" />
                     </ListItem>

                     <ListItem
                        button
                        className={classes.submenuItem}
                        onClick={() => window.open("https://www.concentra.ca/anti-spam", "_blank")}
                     >
                        <ListItemText primary="Anti-Spam" />
                     </ListItem>

                     <ListItem
                        button
                        className={classes.submenuItem}
                        onClick={() => window.open("https://www.concentra.ca/cdic-info", "_blank")}
                     >
                        <ListItemText primary="CDIC Deposit Insurance Information" />
                     </ListItem>

                     <ListItem
                        button
                        className={classes.submenuItem}
                        onClick={() => window.open("https://www.concentra.ca/legal", "_blank")}
                     >
                        <ListItemText primary="Legal" />
                     </ListItem>

                     <ListItem
                        button
                        className={classes.submenuItem}
                        onClick={() => window.open("https://www.concentra.ca/privacy-policy", "_blank")}
                     >
                        <ListItemText primary="Privacy" />
                     </ListItem>

                     <ListItem
                        button
                        className={classes.submenuItem}
                        onClick={() => window.open("https://www.concentra.ca/complaints-english", "_blank")}
                     >
                        <ListItemText primary="Resolving Concerns" />
                     </ListItem>

                     <ListItem
                        button
                        className={classes.submenuItem}
                        onClick={() => window.open("https://www.concentra.ca/complaints-french", "_blank")}
                     >
                        <ListItemText primary="R&eacute;solution des pr&eacute;occupations" />
                     </ListItem>

                  </List>
               </Collapse>
            </>

            <ListItem button onClick={() => navigate("/page/Contacts")}>
               <ListItemIcon>
                  <PhoneIcon />
               </ListItemIcon>
               <ListItemText primary="Contact us" />
            </ListItem>

            {userCanAccessAdminScreens && (
               <>
                  <ListItem button onClick={() => setAdminOpen(!adminOpen)}>
                     <ListItemIcon>
                        <SettingsApplicationsIcon />
                     </ListItemIcon>
                     <ListItemText primary="Administration" />
                     {adminOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListItem>
                  <Collapse in={adminOpen} timeout="auto" unmountOnExit>
                     {userCanManageAnnouncements && (
                        <ListItem button onClick={() => navigate(RouteNames.Announcements)} className={classes.submenuItem}>
                           <ListItemIcon>
                              <AnnouncementIcon />
                           </ListItemIcon>
                           <ListItemText primary="Manage announcements" />
                        </ListItem>
                     )}
                     {userCanManageListservs && (
                        <ListItem button onClick={() => navigate(RouteNames.Listservs)} className={classes.submenuItem}>
                           <ListItemIcon>
                              <AllInboxIcon />
                           </ListItemIcon>
                           <ListItemText primary="Manage listservs" />
                        </ListItem>
                     )}

                     {userCanManageTags && (
                        <ListItem button onClick={() => navigate(RouteNames.Tags)} className={classes.submenuItem}>
                           <ListItemIcon>
                              <LocalOfferIcon />
                           </ListItemIcon>
                           <ListItemText primary="Manage tags" />
                        </ListItem>
                     )}

                     {(userCanAdministerOwnOrganization || userCanAdministerAllOrganizations) && (
                        <ListItem
                           button
                           onClick={() =>
                              navigate(
                                 user.isWyth
                                    ? RouteNames.Organization.replace("/:id?", "")
                                    : RouteNames.Organization.replace(":id", user.organizationId.toString()),
                              )
                           }
                           className={classes.submenuItem}
                        >
                           <ListItemIcon>
                              {showUserAccessReviewNotice ? (< WarningIcon color="error" />) : < BusinessIcon />}
                           </ListItemIcon>
                           <ListItemText primary={userCanAdministerAllOrganizations ? "Manage organizations" : "Manage my organization"} />
                        </ListItem>
                     )}

                     {userHasPermission(Permissions.ViewUsersInAnyOrganization) && (
                        <ListItem button onClick={() => navigate(RouteNames.Users)} className={classes.submenuItem}>
                           <ListItemIcon>
                              <PeopleIcon />
                           </ListItemIcon>
                           <ListItemText primary="Users" />
                        </ListItem>
                     )}


                     {userHasPermission(Permissions.ViewUsersInAnyOrganization) && (
                        <ListItem button onClick={() => navigate(RouteNames.UserGroups)} className={classes.submenuItem}>
                           <ListItemIcon>
                              <SupervisorAccountIcon />
                           </ListItemIcon>
                           <ListItemText primary="User Groups" />
                        </ListItem>
                     )}

                     {userHasPermission(Permissions.ManageAllUserAccessReviews) && (
                        <ListItem button onClick={() => navigate(RouteNames.UserAccessReviews)} className={classes.submenuItem}>
                           <ListItemIcon>
                              <GradeIcon />
                           </ListItemIcon>
                           <ListItemText primary="User Access Reviews" />
                        </ListItem>
                     )}



                  </Collapse>
               </>
            )}

            {userCanAccessManageRatesScreens && (
               <>
                  <ListItem button onClick={() => setManageRatesOpen(!manageRatesOpen)}>
                     <ListItemIcon>
                        <TrendingUpIcon />
                     </ListItemIcon>
                     <ListItemText primary="Manage Rates" />
                     {manageRatesOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListItem>
                  <Collapse in={manageRatesOpen} timeout="auto" unmountOnExit>
                     <List component="div" dense disablePadding>
                        {userCanManageExternalRates && (
                           <ListItem button onClick={() => navigate(RouteNames.ExternalRateChange)} className={classes.submenuItem}>
                              <ListItemText primary="Credit Union Nominee Rates" />
                           </ListItem>
                        )}

                        {userCanManageInternalRates && (
                           <ListItem button onClick={() => navigate(RouteNames.InternalRateChange)} className={classes.submenuItem}>
                              <ListItemText primary="Capital market internal rates" />
                           </ListItem>
                        )}

                        {userCanManageInterestRates && (
                           <ListItem button onClick={() => navigate(RouteNames.InterestRateChange)} className={classes.submenuItem}>
                              <ListItemText primary="Registered plan interest rates" />
                           </ListItem>
                        )}
                     </List>
                  </Collapse>
               </>
            )}




            <UpsertDocumentDialog
               open={postDocumentDialogOpen}
               handleClose={() => setPostDocumentDialogOpen(false)}
               documentIdToEdit={null}
               productId={productRouteMatch.id ? parseInt(productRouteMatch.id) : undefined}
            />

            {confirmingSwitchingUser && (
               <ConfirmSwitchUserDialog
                  userIdTarget={userIdTarget}
                  userTitle={userTitleTarget}
                  handleClose={() => {
                     setConfirmingSwitchingUser(false);
                  }}
                  callback={() => {
                     navigate(RouteNames.Reload);
                  }}
               />
            )}
         </List>
      </>
   );
};

export default Navigation;
