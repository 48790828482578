import { User } from "../users";
import { Organization } from "organizations";
import { Product } from "products";
import { HasId } from "application";
import { Plan } from "../plan";

export enum DocStatus {
   NA = "N/A",
   Received = "Received",
   Retrieved = "Retrieved",
   CorrectionsRequired = "Corrections required",

}

export enum DocSource {
   Wyth = "Concentra",
   Partner = "Partner",
}

export interface Doc extends HasId {
   id: number;
   identifier: string;
   title: string | null;
   fileName: string | null;
   url: string | null;
   typeId: number;
   type: DocType;
   date: Date;
   uploadDate: Date;
   uploadedById: number | null;
   uploadedBy?: User;
   organizationId: number | null;
   organization?: Organization;
   source: DocSource;
   products: Product[];
   jurisdictions: Jurisdiction[];
   tags: Tag[];
   status: DocStatus;
   catalogueNumber?: string;
   version?: string;
   comments?: string;
   plans: Plan[];
}

export const TransientDocType = "Transient";

export interface DocInput {
   id: number;
   url: string | null;
   title: string | null;
   typeId: number;
   date: Date;
   organizationId: number | null;
   productIds: number[];
   jurisdictionIds: number[];
   tags: Tag[];
   status: DocStatus;
   catalogueNumber?: string;
   version?: string;
   comments: string;
   planIds: number[];
}

export interface DocType extends HasId {
   id: number;
   name: string;
   parentId?: number;
   parent?: DocType;
   children: DocType[];
   products: Product[];
   hasCatalogueNumber: boolean;
   catalogueNumberRequired: boolean;
   hasVersion: boolean;
   versionRequired: boolean;
   hasJurisdictions: boolean;
   hasOrganization: boolean;
   isRestrictedForWyth: boolean;
}

export interface DocTypeWithDepth extends DocType {
   depth: number;
}

export interface Jurisdiction extends HasId {
   id: number;
   name: string;
   title: string;
   order: number;
}

export interface Tag extends HasId {
   id: number;
   value: string;
   usageCount?: number;
}
