import { AuthConfig, Settings } from "application";
import { useApi } from "api";
import React, { createContext, useEffect, useState, useContext } from "react";
import WaitingView from "auth/WaitingView";

export const SettingsContext = createContext<{ settings: Settings; authConfig: AuthConfig }>({
   settings: { liveChatURL: "", appInsightsKey: "", showOriginalExceptionMessages: false, hideRespPortal: true, respPortalUrl: "" },
   authConfig: {
      authority: "",
      clientId: "",
      knownAuthority: "",
      resetPasswordAuthority: "",
      requestScopes: [],
      userEmail: "",
   },
});

type Props = React.PropsWithChildren<{}>;

export const SettingsProvider: React.FunctionComponent<Props> = (props) => {
   const [settings, setSettings] = useState<Settings | null>(null);
   const [authConfig, setAuthConfig] = useState<AuthConfig | null>(null);
   const { fetchObjectAsync } = useApi();

   useEffect(() => {
      async function getSettings() {
         try {
            const settingsValue = await fetchObjectAsync<Settings>("/api/settings/get-settings");
            setSettings(settingsValue);
         } catch {
            console.error("Failed to get settings.");
         }
      }
      getSettings();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      async function getAuthConfig() {
         try {
            const authConfigValue = await fetchObjectAsync<AuthConfig>("/Account/GetAuthConfig");
            setAuthConfig(authConfigValue);
         } catch {
            console.error("Failed to get auth config.");
         }
      }
      getAuthConfig();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   if (!settings || !authConfig) {
      return <WaitingView message="Loading..." />;
   }

   return <SettingsContext.Provider value={{ settings, authConfig }}>{props.children}</SettingsContext.Provider>;
};

export function useSettings() {
   const settings = useContext(SettingsContext).settings;

   return {
      liveChatURL: settings?.liveChatURL,
      appInsightsKey: settings?.appInsightsKey,
      showOriginalExceptionMessages: settings?.showOriginalExceptionMessages,
      hideRespPortal: settings?.hideRespPortal,
      respPortalUrl: settings?.respPortalUrl
   };
}

export function useAuthConfig() {
   return useContext(SettingsContext).authConfig;
}
